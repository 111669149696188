import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-re-launched-popover',
  templateUrl: './re-launched-popover.component.html',
  styleUrls: ['./re-launched-popover.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReLaunchedPopoverComponent implements OnInit {
  @Input() info;
  @Input() container;
  @Input() campaignId: number;
  @Output() IsClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  relaunch() {
    this.IsClicked.emit(this.campaignId);
  }
}
