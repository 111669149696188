import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {
  CampaignCopy,
  CampaignDelete,
  CancelCampaignLaunch,
  getCancelCampaignLaunchError,
  getCampaignError,
  getPaginatedCampaigns,
  getCampaignSuccess,
  getInfluencersTypes,
  IAuthenticationState,
  ICampaignState,
  IInfluencersState,
  InfluencersTypeList,
  ResetCampaignState,
  CampaignGetAllPaginate,
  getCancelCampaignLaunch,
  ProjectGet,
  CampaignLaunch, getCampaignLaunch, CampaignLaunchCode, getCampaignLaunchCode, getLoggedInUser, getProject, IProjectState, CampaignUpdate
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {InfluencerType} from '@app/modules/shared/models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CampaignCopyComponent} from '@app/modules/campaign/campaign-copy/campaign-copy.component';
import {DataTableDirective} from 'angular-datatables';
import { RenameTitleModalComponent } from '@app/modules/rename-title-modal/rename-title-modal.component';
import { CampaignService } from '@app/services/campaign.service';
import {SocketService} from '@app/services/socket.service';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import {Constants, smartDateFormat} from '@app/consts';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
  providers: [Constants]
})
export class CampaignsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  influencerTypesById = {};
  dtOptions: any = {};
  private searchDelay;
  private datatableInstance: DataTables.Api<any>;
  ajaxCallback: any;
  dtTrigger: Subject<any> = new Subject();
  routes = routes;
  projectId: number;
  project: any = {};
  selectedTz: any = null;
  campaignCount: boolean;
  campaigns: any = [];
  campaignGroup: any;
  deleteAction = false;
  allCampaigns: any;
  socketSubscription: any;
  influencerType: any;
  unsubscriber = new Subject();
  influencerTypes: InfluencerType[] = [];
  copyAction = false;
  viewType = 'list';
  selectedInfluencerType: number;
  selectedStatus: string;
  firstTime = false;
  currentUser = null;
  isReLaunch = false;
  reLaunchCampaignId: any = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private templateGeneratorService: TemplateGeneratorService,
    private authenticationStore: Store<IAuthenticationState>,
    private campaignStore: Store<ICampaignState>,
    private projectStore: Store<IProjectState>,
    private influencersStore: Store<IInfluencersState>,
    private _modalService: NgbModal,
    public constants: Constants,
    private readonly campaignService: CampaignService,
    private readonly socketService: SocketService
  ) {
    this.campaignStore.dispatch(ResetCampaignState({params: {error: '', paginatedCampaigns: null}}));
    this.subscribeStore();
    this.subscribeToSocket();
    this.influencerType = 'all';
  }

  subscribeStore() {
    this.projectStore.pipe(select(getProject))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(project => {
          if (project) {
            this.project = project;
            this.selectedTz = this.constants.timezone.find(x => x.timeZoneId.toString() === this.project.timezone.toString());
          }
        });
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          this.currentUser = user;
        }
      );
    this.campaignStore.pipe(select(getPaginatedCampaigns))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedCampaigns => {
          if (paginatedCampaigns) {
            if (paginatedCampaigns.list.length > 0 || this.campaignCount) {
              this.campaignCount = true;
              this.campaigns = paginatedCampaigns.list;
              // this.campaigns = this.campaigns.slice().sort((a, b) => Number(new Date(b.launched_at)) - Number(new Date(a.launched_at)));
              if (this.ajaxCallback) {
                this.ajaxCallback({
                  recordsTotal: paginatedCampaigns.paging.total,
                  recordsFiltered: paginatedCampaigns.paging.total,
                  data: []
                });
                setTimeout(() => {
                  (this.datatableInstance as any).columns.adjust();
                }, 500);
              }
            }  else {
              this.campaigns = [];
              this.router.navigate([`/projects/${this.projectId}/campaigns/create`]);
            }
          }
        }
      );

    this.campaignStore.pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.campaignStore.pipe(select(getCampaignSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.deleteAction) {
            this.deleteAction = false;
            this.getCampaigns();
          }
          if (this.copyAction) {
            this.copyAction = false;
            this.getCampaigns();
          }
          if (this.isReLaunch) {
            this.isReLaunch = false;
            this.launchCampaign(this.reLaunchCampaignId, {type: 'relaunch'});
          }
        }
      });

    this.campaignStore.pipe(select(getCancelCampaignLaunch))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data) {
          this.changeCampaignStatus(data.id, 0, data.status);
        }
      });

    this.campaignStore.pipe(select(getCampaignLaunch))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data) {
          this.changeCampaignStatus(data.id, 0, 'launching');
        }
      });

    this.campaignStore.pipe(select(getCampaignLaunchCode))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data) {
          this.campaignStore.dispatch(CampaignLaunch({campaignId: data.id, code: data.launch_code, reLaunch: true}));
        }
      });


    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
        }
      });
  }

  ngOnInit() {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.firstTime = true;
    this.activatedRoute.params.subscribe(params => {
      this.projectId = params.id;
      if (this.projectId) {
        this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
      }
      this.campaignCount = false;
      if (!this.firstTime) {
        this.getCampaigns();
      }
      this.firstTime = false;
      this.getInfluencerTypes();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  datatableSettings() {
    const columns = ['name', 'influencers_count', 'referrals', 'open_rate', 'status', 'email_subject'];
    this.dtOptions = {
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: true,
      columnDefs: [{orderable: false, targets: 'no-sort'},
        { width: '350px', targets: 0 },
        { width: '130px', targets: 1 },
        { width: '130px', targets: 2 },
        { width: '130px', targets: 3 },
        { width: '130px', targets: 4 },
        { width: '130px', targets: 5 },
      ],
      processing: true,
      pageLength: 100,
      paging: true,
      serverSide: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
        const table = this.api().table().node();
        if (table.classList.contains('card-view')) {
          const labels = [];
          $('thead th', table).each(function () {
            labels.push($(this).text());
          });
          $('tbody tr', table).each(function () {
            $(this).find('td').each(function (column) {
              $(this).attr('data-label', labels[column]);
            });
          });

          let max = 0;
          $('tbody tr', table).each(function () {
            max = Math.max($(this).height(), max);
          }).height(max);

        } else {
          $('tbody td', table).each(function () {
            $(this).removeAttr('data-label');
          });

          $('tbody tr', table).each(function () {
            $(this).height('auto');
          });
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: columns[dataTablesParameters.order[0].column]
          };
          this.getCampaigns(pageNumber, pageLength, dataTablesParameters.search.value, orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
      ]
    };
  }

  filterCampaignsByInfluencerType(influencerTypeId: any) {
    this.selectedInfluencerType = null;
    if (influencerTypeId) {
      this.selectedInfluencerType = Number(influencerTypeId);
    }
    this.getCampaigns();
  }

  filterCampaignsByStatus(status: any = 'all') {
    this.selectedStatus = status;
    this.getCampaigns();
  }

  getInfluencerTypes() {
    this.influencersStore.dispatch(InfluencersTypeList());
  }

  getCampaigns(page = 0, perPage = 100, search = '', orderBy = {'column': 'name', 'dir': 'asc'}) {
    this.campaignStore.dispatch(CampaignGetAllPaginate({
      params: {
        project_id: this.projectId,
        ...(this.selectedInfluencerType ? {influencer_type_id: this.selectedInfluencerType} : {}),
        ...((this.selectedStatus && this.selectedStatus !== 'all') ? {status: this.selectedStatus !== 'draft' ? 'not:draft' : this.selectedStatus} : {}),
        options: JSON.stringify({include_project: true, include_segments: true, include_template: true, includePagination: true,
          include_email_stats: true, include_influencer_stats: true, orderBy}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  getInfluencerTypeName(influencerType: number) {
    const type = this.influencerTypes.find(item => Number(item.id) === Number(influencerType));
    return type ? type.name : '';
  }

  deleteCampaign(campaignId: number) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.campaignStore.dispatch(CampaignDelete({campaignId}));
      }
    });
  }

  cancelLaunch(campaignId: number) {
    Swal({
      title: 'Are you sure Want to cancel launch?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.campaignStore.dispatch(CancelCampaignLaunch({campaignId}));
      }
    });
  }

  launchCampaign(campaignId, params = null) {
    this.router.navigateByUrl(
      routes.launchCampaign({
        projectId: this.projectId,
        campaignId: campaignId
      }, params)
    );
  }

  reLaunch(campaignId: number) {
    Swal({
      title: 'Are you sure Want to Re-launch?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.isReLaunch = true;
        this.reLaunchCampaignId = campaignId;
        const campaign = {
          status: 'draft'
        };
        // this.campaignStore.dispatch(CampaignLaunchCode({campaignId}));
        this.campaignStore.dispatch(CampaignUpdate({campaign, campaignId: campaignId}));
      }
    });
  }

  getEmailSendProgressInfo(campaign) {
    const failed = campaign.emailsProgress?.failed || 0;
    const sent = campaign.emailsProgress?.sent || 0;
    const total = campaign.emailsProgress?.total || 0;
    const opened = campaign.emailsProgress?.opened || 0;
    const clicked = campaign.emailsProgress?.clicked || 0;
    if (sent <= 0) {
      return `Delivered: 0%\nSent: 0\nFailed: 0`;
    }
    return `Delivered ${Math.floor(sent * 100 / total)}%\nFailed:${failed}\nSent: ${sent}\nOpened: ${opened}\nClicked: ${clicked}`;
  }

  getEmailSendProgress(campaign) {
    const sent = campaign.emailsProgress?.sent || 0;
    const total = campaign.emailsProgress?.total || 0;
    const opened = campaign.emailsProgress?.opened || 0;
    if (sent <= 0) {
      return `-`;
    }
    return `${Math.floor(opened * 100 / total)}%`;
  }

  getCampaignUpdateInfo(campaign) {
    if (campaign.created_by && !campaign.updated_by) {
      return `Created by ${campaign.created_by?.first_name} ${campaign.created_by?.last_name} - ${smartDateFormat(campaign?.created_at)}`;
    } else if (campaign.updated_by) {
      return `Edited by ${campaign.updated_by?.first_name} ${campaign.updated_by?.last_name} - ${smartDateFormat(campaign?.updated_at)}`;
    }
  }

  getDateTimeBasedOnCampaign(detail, project) {
    return smartDateFormat(detail.launched_at, detail.timezone, null, 'fullDateTimeWithTimeZone', detail.timezone ? detail.timezone : project.timezone);
  }

  copyCampaign(campaignId: number) {
    this.copyAction = true;
    const modelRef: any = this._modalService.open(CampaignCopyComponent, {centered: true, size: 'lg'});
    modelRef.componentInstance.projectId = this.projectId;
    modelRef.result.then((result) => {
      const body = {
        name: result.name,
        project_id: Number(result.project_id),
        options: {
          with_influencers: result.with_influencers
        }
      };
      this.campaignStore.dispatch(CampaignCopy({campaignId, body}));
    }, (reason) => {
    });
  }

  smartDateFormat(datetime, inputTimeZone = null, inputFormat = null, outPutFormatName = null, outPutTimeZone = null, returnObject = false) {
    return smartDateFormat(datetime, inputTimeZone, inputFormat, outPutFormatName, outPutTimeZone, returnObject);
  }

  changeViewType(type) {
    this.viewType = type;
    this.getCampaigns();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.socketSubscription.unsubscribe();
  }

  openRenameModal(entry: any) {
    //
    // Validate entry
    if (!entry?.id) { return; }

    //
    // Open modal
    const modelRef = this._modalService.open(RenameTitleModalComponent, {
      centered: false,
      size: 'sm',
      keyboard: true,
    });

    //
    // Add data to modal
    modelRef.componentInstance.title = 'Campaign';
    modelRef.componentInstance.value = entry.name;

    //
    // On close modal event
    // should validate and update the entry
    modelRef.result.then(async (result: any = {}) => {
      //
      // Validate result
      if (!result?.value) { return; }
      // update database and reload
      await this.campaignService.updateCampaign({ name: result.value }, entry.id).toPromise();
      this.getCampaigns();
    });
  }

  onEditCampaignShare(campaign) {
    this.templateGeneratorService.setCampaignValue(campaign);
    this.router.navigateByUrl(
      routes.campaignShare({
        projectId: this.projectId,
        campaignId: campaign.id
      })
    );
  }

  onEditCampaignPreview(campaign) {
    this.templateGeneratorService.setCampaignValue(campaign);
    this.router.navigateByUrl(
      routes.campaignPreview({
        projectId: this.projectId,
        campaignId: campaign.id
      })
    );
  }

  onEditCampaignTemplate(campaign) {
    this.templateGeneratorService.setCampaignValue(campaign);
    if (campaign.template) {
      this.templateGeneratorService.influencerTypeId();
      const params: any = {sourceTemplateId: campaign.template.id};
      this.router.navigateByUrl(
        routes.campaignTemplates({
          projectId: this.projectId,
          campaignId: campaign.id
        }, params)
      );
    } else  {
      this.router.navigateByUrl(
        routes.campaignSelectTemplate({
          projectId: this.projectId,
          campaignId: campaign.id
        })
      );
    }
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe((message: any) => {
        if (message?.data?.requesterType === 'campaign') {
          this.changeCampaignStatus(message.data.requesterId, message.data.progress, message.data.status, message.data.result);
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  changeCampaignStatus(campaignId, progress, status, result = null) {
    this.campaigns = this.campaigns.map(item => {
      const mapObj = {...item};
      if (item.id === campaignId) {
        mapObj.progress = progress;
        mapObj.status = status;

        if (progress >= 100) {
          delete mapObj.progress;
          delete mapObj.status_message;
          mapObj.status = ['launching', 'draft'].includes(mapObj.status) ? mapObj.status : 'launched';
        }
        if (result) {
          if (result.campaignStatus === 'launch_failed') {
            delete mapObj.progress;
            mapObj.status = result.campaignStatus;
            mapObj.status_message = result.statusMessage;
          }
          if (result.hasOwnProperty('attemptsMade')) {
            mapObj.attemptsMade = result.attemptsMade;
          }
          if (result.campaignStatus === 'launched' && result.redirect) {
            if (result.launched_at) {
              mapObj.launched_at = result.launched_at;
            }
            // if (result.redirect) {
            //   window.open(result.redirect, '_blank');
            // }
          }
        }
      }
      return mapObj;
    });
  }
}
