<div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
  <div class="rightBody">
    <div class="col-12">
      <app-status-bar [isShowStatusBar]="campaign.value.status == 'draft' || launchType === 'relaunch'" [skipLanding]="campaign.value?.landing_page_status === 'skipped'" [step]="step" (SaveButtonClick)="onSaveButtonClick()" [reLaunch]="launchType === 'relaunch'" [campaign]="this.campaign.value"></app-status-bar>
      <div class="card card-box">
        <div class="card-header py-3">
          <div class="card-header--title font-size-lg">
            {{launchStatus === 'done' && launchType !== 'relaunch' ? getLaunchedMessage():'Mobilize Your Influencers'}}
            <span *ngIf="campaign.value.status === 'launch_failed'"><app-info-popover info="{{ campaign.value.status_message }}"></app-info-popover></span>
            <span *ngIf="campaign.value?.status === 'launch_scheduled' && currentUser?.timezone"><app-info-popover info="{{ getCampaignTZDate() }}"></app-info-popover></span>
          </div>
          <div class="card-header--actions pull-right">
          </div>
        </div>
        <div class="card-body p-0">
          <div *ngIf="!launchStatus || launchType === 'relaunch'" class="row p-5">
            <div class="col-5">
              <div class="col-12 p-3">
                <div class="col-md-12 col-sm-12">
                  <div class="timeline-list mb-5 timeline-list--first">
                    <div class="timeline-item timeline-item-icon">
                      <div class="timeline-item--content">
                        <div class="timeline-item--icon-wrapper influencerImg rounded-pill text-white">
                          <i *ngIf="doTheSegmentHaveInfluencers" class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                        <div class="d-flex">
                          <h4 class="timeline-item--label mb-2 font-weight-bold">Influencers Selected. Check</h4>
                          <button class="btn" (click)="redirect('influencer')">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <p *ngIf="doTheSegmentHaveInfluencers && !doTheSegmentHaveInfluencersLoading">
                          {{launchType !== 'relaunch' ? influencerCount : newInfluencerCount + ' new '}} {{influencerType?influencerType:''}} will spread the word about the
                          {{(campaign.value$ | async)?.project.title || 'project'}}.
                        </p>
                        <p *ngIf="doTheSegmentHaveInfluencers && !doTheSegmentHaveInfluencersLoading && launchType === 'relaunch' && campaign.value.service_provider_slug !== 'no_email'" >
                          {{campaignEmailInfluencerCount}} will receive email as well
<!--                          (Note: {{influencerType?influencerType:''}} who have already received the campaign will not receive it.)-->
                        </p>
                        <p *ngIf="doTheSegmentHaveInfluencers && !doTheSegmentHaveInfluencersLoading && campaign.value.service_provider_slug === 'no_email'" class="text-primary">
                          IMPORTANT: You selected the 'No Email' option on the previous page, therefore, zero participants will receive this message upon launch.
                        </p>
                        <p *ngIf="!doTheSegmentHaveInfluencers && !doTheSegmentHaveInfluencersLoading"
                          class="text-danger">
                          Influencers not available for selected segment.
                        </p>
                        <div *ngIf="doTheSegmentHaveInfluencersLoading" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div class="timeline-item timeline-item-icon" *ngIf="!isLandingPageSkipped">
                      <div class="timeline-item--content">
                        <div class="timeline-item--icon-wrapper landingImg rounded-pill text-white">
                          <i *ngIf="isLandingPageSet" class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                        <div class="d-flex">
                          <h4 class="timeline-item--label mb-2 font-weight-bold">Landing Pages Personalized. Check </h4>
                          <button class="btn" (click)="redirect('template')">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <p *ngIf="isLandingPageSet && !isLandingPageSetLoading">
                          Your influencers unique personalized landing pages are ready.
                        </p>
                        <p *ngIf="!isLandingPageSet && !isLandingPageSetLoading" class="text-danger">
                          No landing pages have been created for the selected segment.
                        </p>
                        <div *ngIf="isLandingPageSetLoading" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div class="timeline-item timeline-item-icon">
                      <div class="timeline-item--content">
                        <div class="timeline-item--icon-wrapper landingImg rounded-pill text-white">
                          <i *ngIf="isSocialPageSet" class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                        <div class="d-flex">
                          <h4 class="timeline-item--label mb-2 font-weight-bold">Social Share Customized. Check </h4>
                          <button class="btn" (click)="redirect('socialShare')">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <p *ngIf="isSocialPageSet && !isSocialPageSetLoading">
                          Your influencers social share personalized and is ready.
                        </p>
                        <p *ngIf="!isSocialPageSet && !isSocialPageSetLoading" class="text-danger">
                          No social share customized.
                        </p>
                        <div *ngIf="isSocialPageSetLoading" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div class="timeline-item timeline-item-icon" *ngIf="showEmailCustomize">
                      <div class="timeline-item--content">
                        <div class="timeline-item--icon-wrapper emailImg rounded-pill text-white">
                          <i *ngIf="isCampaignTemplateSet" class="fa fa-check-circle" aria-hidden="true"></i>
                          <i *ngIf="!isCampaignTemplateSet" class="fa fa-times-circle" aria-hidden="true"></i>

                        </div>
                        <div class="d-flex">
                          <h4 class="timeline-item--label mb-2 font-weight-bold">The ASK EMAIL. Check.</h4>
                          <button class="btn" (click)="redirect('email')">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <p *ngIf="isCampaignTemplateSet && !isCampaignTemplateSetLoading">
                          Your email to your influencers is created and ready to go.
                        </p>
                        <p *ngIf="!isCampaignTemplateSet && !isCampaignTemplateSetLoading" class="text-danger">
                          Your domain is not verified. <a class="cursor-pointer"
                            (click)="resendMail()"><u>Click here</u></a> to verify and get instructions.
                        </p>
                        <div *ngIf="isCampaignTemplateSetLoading" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    <div class="timeline-item timeline-item-icon" *ngIf="!allow_campaign_to_launch_when_registration_widget_is_disabled">
                      <div class="timeline-item--content">
                        <div class="timeline-item--icon-wrapper emailImg rounded-pill text-white">
                          <i *ngIf="isWidgetActive" class="fa fa-check-circle" aria-hidden="true"></i>
                          <i *ngIf="!isWidgetActive" class="fa fa-times-circle" aria-hidden="true"></i>

                        </div>
                        <div class="d-flex">
                          <h4 class="timeline-item--label mb-2 font-weight-bold">Registration Widget should be active. Check</h4>
                          <button class="btn" (click)="redirect('widget')">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <p *ngIf="isWidgetActive && !isCampaignTemplateSetLoading">
                          Your Registration Widget is activated and ready to go.
                        </p>
                        <p *ngIf="!isWidgetActive && !isCampaignTemplateSetLoading" class="text-danger">
                          Your Registration Widget is not activated. <a class="cursor-pointer"
                                                          (click)="redirect('widget')"><u>Click here</u></a> to active and get instructions.
                        </p>
                        <div *ngIf="isCampaignTemplateSetLoading" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>

                    <div class="timeline-item timeline-item-icon" *ngIf="!allow_campaign_to_launch_when_registration_widget_is_disabled">
                      <div class="timeline-item--content">
                        <div class="timeline-item--icon-wrapper emailImg rounded-pill text-white">
                          <i *ngIf="!isWidgetPublicAccess" class="fa fa-check-circle" aria-hidden="true"></i>
                          <i *ngIf="isWidgetPublicAccess" class="fa fa-times-circle" aria-hidden="true"></i>

                        </div>
                        <div class="d-flex">
                          <h4 class="timeline-item--label mb-2 font-weight-bold"> Registration Widget should be publicly accessible. Check</h4>
                          <button class="btn" (click)="redirect('widget')">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          </button>
                        </div>
                        <p *ngIf="!isWidgetPublicAccess && !isCampaignTemplateSetLoading">
                          Your Registration Widget is  publicly accessible and ready to go.
                        </p>
                        <p *ngIf="isWidgetPublicAccess && !isCampaignTemplateSetLoading" class="text-danger">
                          Your Registration Widget not publicly accessible <a class="cursor-pointer"
                                                          (click)="redirect('widget')"><u>Click here</u></a> and disable Limit access for testing purpose
                        </p>
                        <div *ngIf="isCampaignTemplateSetLoading" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-7 d-flex justify-content-center align-items-center">
              <img class="img-fluid rounded" src="../../../../assets/images/launchimage.png" alt="lauchImg" />
            </div>
          </div>
          <div *ngIf="launchStatus || launchType === 'relaunch'" class="row p-5" style="text-align: center">
            <div *ngIf="launchStatus === 'start'" class="col-12">
              <div style="margin-bottom: -30px;">
                <span>Launching in progress</span>
              </div>
            </div>
            <div class="col-12" *ngIf="launchStatus === 'start'">
              <div class="lds-ellipsis" style="margin-left: -40px">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <!-- <div *ngIf="launchStatus === 'done'" class="col-12" style="margin-bottom: 50px;">
              <h2>Your campaign has been launched.</h2>
            </div> -->
            <div *ngIf="launchStatus === 'done' && launchType !== 'relaunch'" class="col-12">
              <button class="btn btn-primary mr-2" (click)="redirect('statistics')">View Report</button>
              <button class="btn btn-primary" type="button"
              [routerLink]="'/' + routes.createCampaign({projectId: (campaign.value$ | async)?.project_id})" role="button">
              <span class="btn-wrapper--icon"><i class="fa fa-plus"></i></span>
              <span class="btn-wrapper--label">Start a New Campaign</span>
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="operation-box">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <div class="text-left">
          <button *ngIf="!launchStatus || launchType === 'relaunch'" (click)="redirect('email')" class="btn btn-default mr-2 stopclick float-left"
            type="button">
            Back
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-right">
<!--          <button-->
<!--            [disabled]="!(doTheSegmentHaveInfluencers && (showEmailCustomize ? isCampaignTemplateSet : true) && isLandingPageSet) || (loader$ | async)"-->
<!--            *ngIf="!launchStatus"-->
<!--            class="btn btn-default mr-2"-->
<!--            (click)="openLaunchConfirmationModal()"-->
<!--            type="button">-->
<!--            <span>-->
<!--              <i class="fa fa-spinner fa-spin mr-2" *ngIf="loader$ | async"></i>-->
<!--              {{ schedule?.schedule_date && schedule?.schedule_time ? 'Schedule to Launch at ' + getScheduleDateTime() : 'Launch Or Schedule' }}-->
<!--            </span>-->
<!--          </button>-->
<!--          <span *ngIf="schedule?.schedule_date && schedule?.schedule_time"><app-info-popover info="{{ getScheduleDateTime(true) }}"></app-info-popover></span>-->

          <button
            [disabled]="!(doTheSegmentHaveInfluencers && (showEmailCustomize ? isCampaignTemplateSet : true) && isLandingPageSet) || (loader$ | async) || (!allow_campaign_to_launch_when_registration_widget_is_disabled && !isWidgetActive) ||  (!allow_campaign_to_launch_when_registration_widget_is_disabled && isWidgetPublicAccess)"
            *ngIf="!launchStatus || launchType === 'relaunch'"
            class="btn btn-default"
            [ngClass]="{'mr-2': !schedule?.schedule_date}"
            (click)="launchScheduledCampaign()"
            type="button"
          >
            <span>
              <i class="fa fa-spinner fa-spin mr-2" *ngIf="loader$ | async"></i>
              {{launchType === 'relaunch' ? 'Schedule ReLaunch' : schedule?.schedule_date && schedule?.schedule_time ? 'Schedule to Launch at ' + getScheduleDateTime() : 'Schedule Launch' }}
            </span>
          </button>
          <span class="mr-3" *ngIf="schedule?.schedule_date && schedule?.schedule_time"><app-info-popover info="{{ getScheduleDateTime(true) }}"></app-info-popover></span>

          <button *ngIf="!launchStatus || launchType === 'relaunch'" (click)="openLaunchConfirmationModal()"
            [disabled]="!(doTheSegmentHaveInfluencers && (showEmailCustomize ? isCampaignTemplateSet : true) && isLandingPageSet) || (loader$ | async) || (!allow_campaign_to_launch_when_registration_widget_is_disabled && !isWidgetActive) ||  (!allow_campaign_to_launch_when_registration_widget_is_disabled && isWidgetPublicAccess)"
            class="btn btn-primary float-right mr-2" type="button">
            <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="(loader$ | async)"></i>{{launchType === 'relaunch' ? 'ReLaunch' : 'Launch'}}</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
