<div class="container-fluid pt-3">
  <div class="rightBody">
    <!-- <app-status-bar [step]="step"></app-status-bar> -->

    <form (ngSubmit)="updateProject()" [formGroup]="viewProjectForm" class="needs-validation allEdit activeForm"
          enctype="multipart/form-data"
          id="basicinfo" novalidate>

      <div class="card">
        <div class="card-header py-3">
          <div class="card-header--title font-size-lg">
            Edit Project
          </div>
        </div>
        <!-- Basic Infromaion panel -->
        <div class="card card-inline">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">Project's details</div>
          </div>
          <div class="card-body p-0">
            <div class="form-wraper">
              <div class="row">

                <!-- <div class="col-lg-3 col-md-3 col-sm-12 text-center">
                  <img class="full-img" src="../../assets/images/event-logo-1.png" />
                </div> -->

                <div class="col-lg-3 col-md-3 col-sm-12 text-center project-add-image-container">
                  <div class="image-event">
                    <img [src]="imageBinary" *ngIf="imageBinary" class="img-fluid d-block m-auto" id="event-logo-preview"/>
                    <button (click)="uploadLogo(fileInput)" [disabled]="submitted && fileUploadProgress > 0 && fileUploadProgress < 100" class="btn btn-default" id="logoUploaderBtn" type="button">
                      <li class="fa fa-upload"></li>
                      {{fileUploadProgress ? fileUploadProgress + '%' : ''}} {{imageBinary ? 'Replace' : 'Upload'}} Project Logo
                    </button>
                    <app-info-popover
                      info="Acceptable formats are jpeg and png. (Min  800x800px, max 2048x2048px)">
                    </app-info-popover>
                    <input #fileInput (change)="onProjectImageChanged($event);" accept="image/*"
                           class="file-input" id="projectLogo" type="file">
                  </div>
                </div>

                <div class="col-md-9 col-md-9 col-sm-12">

                  <div class="row">

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <input class="floating-input" formControlName="title"
                          [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
                               placeholder=" "
                               type="text">
                        <span class="highlight"></span>
                        <label>Project's Name <span class="red">*</span></label>
                        <div *ngIf="submitted && f.title.errors" class="bar error-brd">
                          <div *ngIf="f.title.errors.required" class="invalid-text">Name is
                            required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-ms-12" *ngIf='isAdmin'>
                      <div class="floating-label">
                        <i aria-hidden="true" class="fa fa-angle-down chevronSelectOptionIcon"></i>
                        <app-auto-complete-select
                          [hideLabel]="true"
                          [isCreatable]="false"
                          [onlyBottomBorder]="false"
                          [placeholder]="'Select Client'"
                          [selectOptions]="clients"
                          [selectedValue]="f.client_id.value || null"
                          (optionSelected)="clientSelected($event)"
                          (searchValue)="handleSearchValue($event)"
                        ></app-auto-complete-select>
<!--                        <select class="floating-select chevronSelectOption"-->
<!--                                formControlName="client_id"-->
<!--                                onchange="this.setAttribute('value', this.value);" value="{{f.client_id.value}}">-->

<!--                          <option *ngFor="let planner of planners"-->
<!--                                  [value]="planner.id" >{{ planner.name | uppercase}}</option>-->
<!--                        </select>-->
                        <span class="highlight"></span>
<!--                        <label>Client Name</label>-->
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <i aria-hidden="true" class="fa fa-angle-down chevronSelectOptionIcon"></i>
                        <select class="floating-select chevronSelectOption"
                                formControlName="industry"
                                onchange="this.setAttribute('value', this.value);" value="{{f.industry.value}}">

                          <option *ngFor='let industry of industryList' [value]="industry.id">{{
                            industry.name
                            }}</option>
                        </select>
                        <span class="highlight"></span>
                        <label>Industry </label>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <i aria-hidden="true" class="fa fa-angle-down chevronSelectOptionIcon"></i>
                        <select
                          class="floating-select chevronSelectOption"
                          formControlName="event_type"
                          onchange="this.setAttribute('value', this.value);"
                          value="{{f.event_type}}"
                        >
                          <option
                            *ngFor="let event of eventList"
                            [value]="event.id"
                          >{{ event.name }}</option
                          >
                        </select>
                        <span class="highlight"></span>
                        <label>Event Type<span class="red">*</span></label>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <i aria-hidden="true" class="fa fa-angle-down chevronSelectOptionIcon"></i>
                        <select
                          class="floating-select chevronSelectOption"
                          formControlName="is_paid"
                          onchange="this.setAttribute('value', this.value);"
                          value="{{f.is_paid}}"
                        >
                          <option
                            *ngFor="let item of isPaidList"
                            [value]="item.id"
                          >{{ item.name }}</option
                          >
                        </select>
                        <span class="highlight"></span>
                        <label>Registration Type<span class="red">*</span></label>
                      </div>
                  </div>
                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <input
                          placeholder=" "
                          class="floating-input" formControlName="register_average_price"
                          type="number" step="any">
                        <span class="highlight"></span>
                        <label>Average Ticket price</label>
                      </div>
                    </div>
                  </div>

                  <div class="row">

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <input [ngClass]="{ 'is-invalid': submitted && f.venue.errors }"
                               class="floating-input" formControlName="venue"
                               placeholder=" "
                               type="text">
                        <span class="highlight"></span>
                        <label for="">Project's Location</label>
                        <div *ngIf="submitted && f.venue.errors" class="bar error-brd">
                          <div *ngIf="f.venue.errors.required" class="invalid-text">Location is
                            required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <i aria-hidden="true" class="fa fa-angle-down chevronSelectOptionIcon"></i>
                        <ng-moment-timezone-picker class="dropdown-panel-timezone" [formControlName]="'timezone'" [customPlaceholderText]='"Time Zone"' [clearable]='true'></ng-moment-timezone-picker>
                        <span class="highlight"></span>
                      </div>
                    </div>

                  </div>

                  <div class="row mt-3">

                    <div class="col-md-6">
                      <div class="row">

                        <div class="col-md-6">
                          <div class="calendar floating-label">
                            <div class="form-group datePicker">
                              <input [minDate]="min" bsDatepicker class="form-control floating-input"
                                     formControlName="start_date"
                                     placeholder=" " type="text"
                                     [bsConfig]="{dateInputFormat: 'MM/DD/YYYY'}">
                              <span class="highlight"></span>
                              <label>Project Start Date <span class="red">*</span></label>
                              <span class="date-ic">
                              <i class="fa fa-calendar"><span class="red">*</span></i>
                            </span>
                              <div *ngIf="submitted && f.start_date.errors"
                                   class="bar error-brd">
                                <div *ngIf="f.start_date.errors.required"
                                     class="invalid-text">Start Date is
                                  required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="Time">
                            <div class="toggle-example custom-timePicker">
                              <input [disableClick]="true"
                                     [ngxTimepicker]="toggleTimepicker"
                                     formControlName="start_time" placeholder="Time"
                                     readonly>
                              <ngx-material-timepicker-toggle
                                [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
                              <ngx-material-timepicker
                                #toggleTimepicker></ngx-material-timepicker>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="row">

                        <div class="col-md-6 ">
                          <div class="calendar floating-label">
                            <div class="form-group datePicker">
                              <input [minDate]="max" bsDatepicker class="form-control floating-input"
                                     formControlName="end_date"
                                     placeholder=" " type="text"
                                     [bsConfig]="{dateInputFormat: 'MM/DD/YYYY' }">
                              <span class="highlight"></span>
                              <label>Project End Date <span class="red">*</span></label>
                              <span class="date-ic">
                              <i class="fa fa-calendar"><span class="red">*</span></i>
                            </span>
                              <div *ngIf="submitted && f.end_date.errors"
                                   class="bar error-brd">
                                <div *ngIf="f.end_date.errors.required"
                                     class="invalid-text">End Date is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="Time">
                            <div class="toggle-example custom-timePicker">
                              <input [disableClick]="true"
                                     [ngxTimepicker]="toggleTimepickerNew"
                                     formControlName="end_time" placeholder="Time"
                                     readonly>
                              <ngx-material-timepicker-toggle [for]="toggleTimepickerNew">
                              </ngx-material-timepicker-toggle>
                              <ngx-material-timepicker
                                #toggleTimepickerNew></ngx-material-timepicker>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="calendar floating-label">
                        <div class="form-group datePicker">
                          <input [minDate]="min" bsDatepicker class="form-control floating-input"
                                 formControlName="activation_starts_at"
                                 placeholder=" " type="text"
                                 [bsConfig]="{dateInputFormat: 'MM/DD/YYYY'}">
                          <span class="highlight"></span>
                          <label>Activation Start Date <span class="red">*</span></label>
                          <span class="date-ic">
                              <i class="fa fa-calendar">
                                <span class="red">*</span>
                              </i>
                          </span>
                          <div *ngIf="submitted && f.activation_starts_at.errors"
                               class="bar error-brd">
                            <div *ngIf="f.activation_starts_at.errors.required"
                                 class="invalid-text">Active Duration Start Date is
                              required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="calendar floating-label">
                        <div class="form-group datePicker">
                          <input [minDate]="max" bsDatepicker class="form-control floating-input"
                                 formControlName="activation_ends_at"
                                 placeholder=" " type="text"
                                 [bsConfig]="{dateInputFormat: 'MM/DD/YYYY' }">
                          <span class="highlight"></span>
                          <label>Activation End Date <span class="red">*</span></label>
                          <span class="date-ic">
                              <i class="fa fa-calendar">
                                <span class="red">*</span>
                              </i>
                            </span>
                          <div *ngIf="submitted && f.activation_ends_at.errors"
                               class="bar error-brd">
                            <div *ngIf="f.activation_ends_at.errors.required"
                                 class="invalid-text">Active Duration End Date is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label input-group input-group-sm info-input">
                        <input [ngClass]="{ 'is-invalid': submitted && f.registration_url.errors }"
                               class="floating-input"
                               formControlName="registration_url" placeholder=" "
                               required="required"
                               type="text">
                        <span class="highlight"></span>
                        <label>Call To Action Url<span class="red">*</span></label>
                        <div *ngIf="submitted && f.registration_url.errors" class="bar error-brd">
                          <div *ngIf="f.registration_url.errors.pattern" class="invalid-text">
                            Please enter valid url.
                          </div>
                          <div *ngIf="f.registration_url.errors.required" class="invalid-text">
                            Please enter Registration
                            Link.
                          </div>
                        </div>
                        <app-info-popover
                          info="This could be your registration link or downloadable form link">
                        </app-info-popover>
                      </div>
                    </div>


                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <input [ngClass]="{ 'is-invalid': submitted && f.website_url.errors }"
                               class="floating-input" formControlName="website_url"
                               placeholder=" "
                               type="text">
                        <span class="highlight"></span>
                        <label>Project's Website<span class="red">*</span></label>
                        <div *ngIf="submitted && f.website_url.errors" class="bar error-brd">
                          <div *ngIf="f.website_url.errors.pattern" class="invalid-text">Please
                            enter valid url.
                          </div>
                          <div *ngIf="f.website_url.errors.required" class="invalid-text">Please
                            enter Project Website.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <input
                          placeholder=" "
                          class="floating-input" formControlName="google_utm_params"
                          type="text">
                        <span class="highlight"></span>
                        <label>Google UTM Params</label>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label input-group input-group-sm info-input">
                        <input [ngClass]="{ 'is-invalid': submitted && f.emails_source_message.errors }"
                               class="floating-input"
                               formControlName="emails_source_message" placeholder=" "
                               type="text">
                        <span class="highlight"></span>
                        <label>Emails Source Message</label>
                        <div *ngIf="submitted && f.emails_source_message.errors" class="bar error-brd">
                          <div *ngIf="f.emails_source_message.errors.required" class="invalid-text">
                            Please enter emails source message.
                          </div>
                        </div>
                        <app-info-popover
                          info="This will be displayed to your audience in the emails they receive. Example: You signed up to our newsletter in our previous event">
                        </app-info-popover>
                      </div>
                    </div>

                  </div>

                  <div class="d-block">
                    <h5 class="form-sub-heading mt-0">Project Important Dates</h5>
                  </div>

                  <div *ngFor="let dates of dateFormGroup.controls; let i = index" formArrayName="dates">
                    <div [formGroupName]="i">

                      <div class="row">

                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="floating-label">
                            <input class="floating-input" formControlName="important_text"
                                   placeholder=" "
                                   type="text">
                            <span class="highlight"></span>
                            <label>Name</label>
                          </div>
                        </div>

                        <div class="col-10 col-lg-4 col-md-4 col-sm-10">
                          <div class="calendar">
                            <div class="floating-label datePicker">
                              <input bsDatepicker class="form-control" class="floating-input"
                                     formControlName="important_date"
                                     placeholder="Select Date" type="text">
                              <span class="date-ic"><i class="fa fa-calendar"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-lg-2 col-md-2 col-sm-2 d-flex align-items-center">
                          <a (click)="removeDatesButtonClick(i)"
                             *ngIf="!(dateFormGroup.controls.length-1 === i)"
                             class="pointer">
                            <i class="fa fa-times-circle remove-btn"></i>
                          </a>
                          <a (click)="addDatesButtonClick()"
                             *ngIf="dateFormGroup.controls.length-1 === i"
                             class="pointer">
                            <i class="fa fa-plus-circle add-btn"></i>
                          </a>
                        </div>

                      </div>

                    </div>
                  </div>

                  <!-- <div class="text-right mt-3 editView-btn editView-btn1">
                    <button id="cancelBtnbsinfo" class="outline-blue-btn mr-2 cancel-btn" type="button">Cancel</button>
                    <button class="outline-blue-btn" type="button">Save</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Basic Information End panel -->

        <!-- Social Media Information panel -->
        <div class="card card-inline">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">Social Media Information</div>
          </div>
          <div class="card-body p-0">
            <div class="form-wraper">
              <div class="row">

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="floating-label">
                    <input class="floating-input" formControlName="hashtag" placeholder="#007 Project"
                           type="text">
                    <span class="highlight"></span>
                    <label>Project's Officials Hashtag</label>
                  </div>
                </div>

              </div>

              <div class="row">

                <div class="col-lg-6 col-md-6 col-ms-12 ">
                  <div class="floating-label">
                    <input class="floating-input floating-placeholder" formControlName="facebook"
                           placeholder="https://www.facebook.com/snoball/"
                           type="text">
                    <span class="highlight"></span>
                    <label>Facebook</label>
                    <div *ngIf="submitted && f.facebook.errors" class="bar error-brd">
                      <div *ngIf="f.facebook.errors" class="invalid-text">Please enter valid url.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label">
                    <input class="floating-input floating-placeholder" formControlName="twitter"
                           placeholder="https://www.twitter.com/snoball/"
                           type="text">
                    <span class="highlight"></span>
                    <label>Twitter</label>
                    <div *ngIf="submitted && f.twitter.errors" class="bar error-brd">
                      <div *ngIf="f.twitter.errors" class="invalid-text">Please enter valid url.</div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">

                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label">
                    <input class="floating-input floating-placeholder" formControlName="linkedin"
                           placeholder="https://www.linkedin.com/in/username/"
                           type="text">
                    <span class="highlight"></span>
                    <label>LinkedIn</label>
                    <div *ngIf="submitted && f.linkedin.errors" class="bar error-brd">
                      <div *ngIf="f.linkedin.errors" class="invalid-text">Please enter valid url.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label">
                    <input class="floating-input floating-placeholder" formControlName="instagram"
                           placeholder="https://www.instagram.com/snoball/"
                           type="text">
                    <span class="highlight"></span>
                    <label>Instagram</label>
                    <div *ngIf="submitted && f.instagram.errors" class="bar error-brd">
                      <div *ngIf="f.instagram.errors" class="invalid-text">Please enter valid url.
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <!-- <div class="text-right mt-3 editView-btn editView-btn2">
                <button id="cancelBtnsocial" class="outline-blue-btn mr-2" type="button">Cancel</button>
                <button class="outline-blue-btn" type="button">Save</button>
              </div> -->
<!--              <div class="row">-->
<!--                <div class="col-lg-6 col-md-6 col-sm-12 ">-->
<!--                  <div class="right-padding">-->
<!--                    <div class="floating-label">-->
<!--                      <input-->
<!--                        class="floating-input"-->
<!--                        formControlName="ga_code"-->
<!--                        placeholder=" "-->
<!--                        type="text"-->
<!--                      />-->
<!--                      <span class="highlight"></span>-->
<!--                      <label>GA Code</label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <div *ngFor="let socials of socialFormGroup.controls; let i = index" formArrayName="socials">
                <div [formGroupName]="i">
                  <div class="row">

                    <div class="col-lg-6 col-md-6 col-ms-12">
                      <div class="floating-label">
                        <input class="floating-input" formControlName="social_label" id="" placeholder=" "
                               type="text"/>
                        <span class="highlight"></span>
                        <label for="">Name
                        </label>
                      </div>
                    </div>

                    <div class="col-10 col-lg-4 col-md-4 col-sm-10">
                      <div class="floating-label">
                        <input class="floating-input" formControlName="social_value" id="" placeholder=" "
                               type="text"/>
                        <span class="highlight"></span>
                        <label for="">URL
                        </label>
                      </div>
                    </div>

                    <div class="col-2 col-lg-2 col-md-2 col-sm-2 d-flex align-items-center">
                      <a (click)="addSocialsButtonClick()"
                         *ngIf="socialFormGroup.controls.length-1 === i"
                         class="pointer">
                        <i class="fa fa-plus-circle add-btn"></i>
                      </a>
                      <a (click)="removeSocialsButtonClick(i)"
                         *ngIf="!(socialFormGroup.controls.length-1 === i)"
                         class="pointer">
                        <i class="fa fa-times-circle remove-btn"></i>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Social Media Information End panel -->

        <!-- Setting panel -->
        <div class="card card-inline">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">
              Settings
            </div>
          </div>
          <div class="card-body form-padding">
            <div class="form-wraper">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                  <div class="action-check-box align-items-center">
                    <input formControlName="sessions_can_be_updated_influencer" id="sessions_can_be_updated_influencer" type="checkbox" class="styled-checkbox" >
                    <label for="sessions_can_be_updated_influencer" class="position-relative ml-2">Can Speakers update their session</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Setting End panel -->

        <!-- Exclude Information panel -->
        <div class="card card-inline">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">Exclude Emails / IPs</div>
          </div>
          <div class="card-body p-0">
            <div class="form-wraper">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="floating-label">
                    <textarea class="floating-input floating-placeholder" formControlName="exclude_emails" placeholder="demo@snoball.it, user@snoball.it, ..."
                           type="text"></textarea>
                    <span class="highlight"></span>
                    <label>Exclude Emails</label>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="floating-label">
                    <textarea class="floating-input floating-placeholder" formControlName="exclude_ips" placeholder="127.0.0.1, 198.168.0.0, ..."
                           type="text"></textarea>
                    <span class="highlight"></span>
                    <label>Exclude Ips</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Exclude Information End panel -->

        <!--          CUSTOM VARIABLES-->
        <div class="card card-inline">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">
              Custom Variables
              <app-info-popover
                info="Custom variables can be used to easily customize certain aspects of the campaign pages, campaign emails, etc">
              </app-info-popover>
            </div>
          </div>
          <div class="card-body form-padding">
            <div class="form-wraper">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_1"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_1.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 1</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_1.errors">
                      <div class="invalid-text" *ngIf="f.custom_1.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_1')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_2"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_2.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 2</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_2.errors">
                      <div class="invalid-text" *ngIf="f.custom_2.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_2')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_3"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_3.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 3</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_3.errors">
                      <div class="invalid-text" *ngIf="f.custom_3.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_3')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_4"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_4.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 4</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_4.errors">
                      <div class="invalid-text" *ngIf="f.custom_4.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_4')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_5"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_5.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 5</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_5.errors">
                      <div class="invalid-text" *ngIf="f.custom_5.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_5')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_6"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_6.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 6</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_6.errors">
                      <div class="invalid-text" *ngIf="f.custom_6.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_6')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_7"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_7.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 7</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_7.errors">
                      <div class="invalid-text" *ngIf="f.custom_7.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_7')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_8"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_8.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 8</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_8.errors">
                      <div class="invalid-text" *ngIf="f.custom_8.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_8')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_9"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_9.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 9</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_9.errors">
                      <div class="invalid-text" *ngIf="f.custom_9.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_9')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input">
                    <input class="floating-input" formControlName="custom_10"
                           [ngClass]="{ 'is-invalid': submitted && f.custom_10.errors }"
                           type="text" placeholder=" ">
                    <span class="highlight"></span>
                    <label>Custom 10</label>
                    <div class="bar error-brd" *ngIf="submitted && f.custom_10.errors">
                      <div class="invalid-text" *ngIf="f.custom_10.errors">Enter valid value</div>
                    </div>
                    <div class="inputs">
                      <app-variables
                        [variables]="variables"
                        (onVariableClick)="updateValue($event, 'custom_10')"
                      ></app-variables>
                      <app-file-browser
                        [clientId]="project?.client_id"
                        [projectId]="project?.id">
                      </app-file-browser>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-inline" [hidden]="!isAdmin">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">
              Notes
            </div>
          </div>
          <div class="py-4 px-4">
            <textarea class="form-control project-list-textarea" formControlName="notes" placeholder="add notes" rows="4"></textarea>
          </div>
        </div>
        <div class="card card-inline" [hidden]="!isAdmin">
          <div class="card-header py-3">
            <div class="card-header--title font-size-lg">
              Report Analysis
            </div>
          </div>
          <div class="card-body form-padding">
            <div class="form-wraper">
              <div class="row">
                <div *ngIf="!isLoadEditor" class="col-lg-12 col-md-12 col-ms-12 spinner-container">
                  <i class="fa fa-spinner fa-spin"></i>
                </div>
                <div [ngClass]="{'d-none': !isLoadEditor}" class="col-lg-12 col-md-12 col-ms-12">
                  <div class="floating-label input-group input-group-sm variable-input flora-analysis-editor">
<!--                              <textarea class="floating-input" rows="4" id="analysis_editor" name="editor" placeholder="Report Analysis" style="height: unset"-->
<!--                                        formControlName="report_analysis"></textarea>-->
                    <textarea cols="90" id="analysis_editor" name="editor"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card mt-4">
            <div class="card-body form-padding">
                <div class="form-title d-block">
                    <h4>Project Branding Assets</h4>
                </div>
                <div class="form-wraper">
                    <div class="row ">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="floating-label">
                                <input
                                        class="floating-input"
                                        value="{{ this.viewProjectForm.get('fonts').value.length ? this.viewProjectForm.get('fonts').value[0].name : '' }}"
                                        (change)="onFontsLinkChange($event)"
                                        type="text"
                                />
                                <span class="highlight"></span>
                                <label>Fonts Link</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div *ngIf="editInfo" class="text-right mt-3 mb-3">
          <button class="blue-btn mr-2" type="submit">Update</button>
        </div>
      </div>
    </form>

    <!-- <div *ngIf="!editInfo" class="text-right mt-3">
        <button [routerLink]="/project/{{ id }}/add-influencer" class="blue-btn mr-2" type="submit">Next</button>
        <button class="blue-btn mr-2" routerLink="/project/{{ id }}/add-influencer" type="submit">
            Next
        </button>
    </div> -->
  </div>
</div>
