import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  Renderer2,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import {Router} from '@angular/router';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import * as routes from '@app/routes';

import {EMAIL_TEMPLATE_TYPE_ID} from '@app/consts';
import {select, Store} from '@ngrx/store';
import {
  CampaignUpdate,
  CampaignUpdateError,
  CampaignUpdateSuccess,
  getCampaignUpdate,
  getInfluencerError,
  getInfluencers,
  getMailServiceProvider,
  getMailServiceProviderError,
  getMailVerifyError,
  getMailVerifyStatus,
  getTemplate,
  getTemplateSuccess,
  ICampaignState,
  IInfluencersState,
  IMailVerifyState,
  InfluencersListByProject,
  ITemplateState,
  MailServiceProvider,
  MailVerifySender,
  MailVerifySenderStatus,
  ResetTemplateState,
  TemplateGet,
  getLoggedInUser,
  IAuthenticationState, IProjectState, getProjects, ResetInfluencerState, ResetMailVerifyState,
  ProjectPlanners, getProjectPlanners
} from '@app/stores';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {Observable} from 'rxjs/Observable';
import {LoaderService} from '@app/services/loader.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {EditorComponent} from '@app/modules/template-generator/editor/editor.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CampaignService} from '@app/services/campaign.service';
import {MailVerifyService} from '@app/services/mail-verify.service';
import {MailerDnsInfoModalComponent} from '@app/modules/mailer-dns-info-modal/mailer-dns-info-modal.component';
import {TemplateRevisionsComponent} from '@app/_components/template-revisions/template-revisions.component';
import Swal from 'sweetalert2';
import {VerificationComponent} from '@app/_components/verification/verification.component';
import {VerificationByComponent} from '@app/_components/verification-by/verification-by.component';
import {TemplateService} from '@app/services/template.service'
import { project } from '@app/routes';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({transform: 'scale3d(.3, .3, .3)'}),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({transform: 'scale3d(.0, .0, .0)'}))
      ])
    ])
  ]
})

export class PreviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('editor') editor: EditorComponent;
  public campaign: any;
  public influencerType;
  public template;
  public influencerCount;
  public template_type_id = EMAIL_TEMPLATE_TYPE_ID;
  public variables = {};
  public variablesFor = '';
  public domainVerificationInfo = false;
  public form: UntypedFormGroup;
  public fromEmailVerified = '';
  public toVerifyEmail = false;
  public buttonText = 'Yes, I\'ll share!';
  public buttonColor = '';
  public buttonTextColor = '';
  public linkColor = '#6fa8dc';
  public linkHoverColor = '#4191ff';
  public emailBackgroundColor = '#f0fbff';
  public buttonSpacing = 10;
  public listSpacing = 1;
  public paragraphSpacing = 25;
  public defaultShareLink = '#InfluencerShareLink#';
  public templateMeta: any = {};
  public campaignMeta: any = {};
  public sectionsStatus = {email: true, widget: false};
  public hasJsonBody = false;
  public isOldEditor = true;
  public editorInfo = null;
  public showEditor = {
    'email-builder-js': false
  };
  public templateData = {
    body: null,
    body_json: null,
  };  
  previewWidget = false;
  visible = false;
  submitted = false;
  debounceTimeOut = null;
  isAdmin = false;
  isIncludeContent = false;

  public blocks = [];
  public campaignForm: UntypedFormGroup;
  public step;
  unsubscriber = new Subject();
  loader$: Observable<boolean>;
  emailPreviewClicked = false;
  previousEmail: string;
  nextButtonClicked = false;
  isExit = false;
  editorLoaded = false;
  resettingTemplate = false;
  @ViewChild('sendTestEmail', {static: true}) sendTestEmail: TemplateRef<any>;
  testEmail;
  sampleInfluencerEmail = null;
  testEmailError;
  sampleInfluencerEmailError;
  public mailServiceProviders: any[] = [];
  currentUser: any = {};
  previousFromEmail = '';
  additionalContent = '';
  projects = [];
  planners = [];
  selectedProject: any = null;
  variableLoaded = false;
  landingpageButtonColor: any = '';
  isTestEmailDisable = false;
  randomStr = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
  @ViewChild('subject', { static: false }) subjectLine: ElementRef;
  private TemplateService: any;
  testMailForm: UntypedFormGroup;
  isNotificationSet$ = new BehaviorSubject(false);
  recipients: UntypedFormArray;
  formLenght$ = new BehaviorSubject(1);
  formLength: number;
  mode = 'iframe';
  constructor(
    private router: Router,
    private templateGeneratorService: TemplateGeneratorService,
    private influencerStore: Store<IInfluencersState>,
    private campaignStore: Store<ICampaignState>,
    private mailVerifyStore: Store<IMailVerifyState>,
    private templateStore: Store<ITemplateState>,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private loaderService: LoaderService,
    private templateService : TemplateService,
    private projectStore: Store<IProjectState>,
    private _modalService: NgbModal,
    private authenticationStore: Store<IAuthenticationState>,
    private campaignService: CampaignService,
    private mailVerifyService: MailVerifyService,
    private renderer: Renderer2
  ) {
    this.onEditorChange = this.onEditorChange.bind(this);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  subscribeStore() {
    this.templateStore.pipe(select(getTemplate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data && this.resettingTemplate) {
          this.resettingTemplate = false;
          const oldTemplateData = Object.assign({}, this.template);
          this.template = null;

          setTimeout(() => {
            oldTemplateData.body = data.body;
            oldTemplateData.meta = data.meta;
            this.template = oldTemplateData;
            this.templateData = {
              body:  this.template.body,
              body_json:  this.template.body_json,
            }

            if (data.meta) {
              this.templateMeta = (typeof data.meta === 'string') ? JSON.parse(data.meta) : data.meta;
            }
            this.buttonText = this.templateMeta?.buttonText || 'I\'m in';
            this.buttonColor = this.templateMeta?.buttonColor || this.landingpageButtonColor;
            this.buttonTextColor = this.templateMeta?.buttonTextColor || '#ffffff';;
            this.linkColor = this.templateMeta?.linkColor || '#6fa8dc';
            this.linkHoverColor = this.templateMeta?.linkHoverColor || '#4191ff';
            this.emailBackgroundColor = this.templateMeta?.emailBackgroundColor || '#f0fbff';
            this.buttonSpacing = this.templateMeta?.buttonSpacing || '';
            this.listSpacing = this.templateMeta?.listSpacing || 1;
            this.paragraphSpacing = this.templateMeta?.paragraphSpacing || '';
            this.replaceToProjectLogo();
          }, 200);
        }
      });
    this.templateStore.pipe(select(getTemplateSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.emailPreviewClicked) {
            this.emailPreviewClicked = false;
            this.toastr.success(success, 'Success');
          }
        }
      });
    this.campaignStore.pipe(select(getCampaignUpdate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign) {
          if (this.previewWidget) {
            this.previewWidget = false;
            this.templateGeneratorService.widgetPreview(campaign.id);
          }
          if (this.nextButtonClicked) {
            if (this.isExit) {
              this.router.navigateByUrl('/' + routes.campaigns({projectId: this.campaign.project_id}));
            } else {
              this.router.navigateByUrl(
                routes.launchCampaign({
                  projectId: this.campaign.project_id,
                  campaignId: this.campaign.id
                })
              );
            }
          }
        }
      });
    this.projectStore.pipe(select(getProjects))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(projects => {
          if (projects) {
            this.projects = projects;
            this.replaceToProjectLogo();
          }
        });
    this.projectStore.pipe(select(getProjectPlanners))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(projectPlanners => {
        if(projectPlanners) {
          this.planners = projectPlanners;
          this.populatePlannersEmail();
        }
      });
    this.influencerStoreSubscribe();
    this.mailVerifyStoreSubscribe();
  }

  replaceToProjectLogo() {
    if (!this.campaign?.project_id) {
      return null;
    }
    if (!this.projects || !this.projects.length) {
      return null;
    }
    if (!this.selectedProject) {
      this.selectedProject = this.projects.find(x => Number(x.id) === Number(this.campaign?.project_id));
      if (!this.selectedProject) {
        return null;
      }
    }
    if (!this.selectedProject.image) {
      return null;
    }
    const imgElement = document.querySelector('[data-src-variable="#ProjectImage#"]');
    if (!imgElement) {
      return null;
    }
    imgElement.setAttribute('src', this.selectedProject.image);
  }

  influencerStoreSubscribe() {
    this.influencerStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencerStore.pipe(select(getInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencers => {
        if (influencers && influencers.length > 0) {
          this.filterInfluencers(influencers);
        }
      });
  }

  mailVerifyStoreSubscribe() {
    this.influencerStore.pipe(select(getMailServiceProviderError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencerStore.pipe(select(getMailServiceProvider))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data && data.length > 0) {
          this.mailServiceProviders = data;
          this.mailServiceProviders = Object.assign([],this.mailServiceProviders);
          if(!this.isAdmin) {
            for(let i = 0; i < this.mailServiceProviders.length; i++) {
                if (this.mailServiceProviders[i].slug === "mailchimp") {
                  this.mailServiceProviders.splice(i,1);
                }
                if(this.mailServiceProviders[i].slug === "no_email") {
                  this.mailServiceProviders[i] = Object.assign({},this.mailServiceProviders[i]);
                  this.mailServiceProviders[i].name = 'Skip Sending Emails';
                }
                if(this.mailServiceProviders[i].slug === "aws_ses") {
                  this.mailServiceProviders[i] = Object.assign({},this.mailServiceProviders[i]);
                  this.mailServiceProviders[i].name = 'Send Emails';
                }
            }
          }
        }
      });
  }

  filterInfluencers(influencers) {
    if (this.campaign) {
      const segment = this.campaign.segments[0];
      const filteredInfluencers = influencers.filter(influencer => {
        return Number(influencer.project_influencer_segment_id) === Number(segment.id);
      });
      this.influencerCount = filteredInfluencers.length;
    } else {
      setTimeout(() => {
        this.filterInfluencers(influencers);
      }, 1000);
      return;
    }
  }

  ngOnInit() {
    this.initTestForm();
    this.templateGeneratorService.forceReloadCampaign();
    this.templateGeneratorService.subscribeStore();
    this.loader$ = this.loaderService.loader$;
    this.subscribeStore();
    this.initializeCampaignForm();
    this.subscribeToCampaign();
    this.mailVerifyStore.dispatch(ResetMailVerifyState({params: {error: '', serviceProviders: null}}));
    // if (this.currentUser && this.currentUser.user_type === '1') {
    this.mailVerifyStore.dispatch(MailServiceProvider({params: {}}));
    this.authenticationStore.pipe(select(getLoggedInUser)).subscribe(user => {
          this.currentUser = user;
          this.isAdmin = this.currentUser.role_user[0].role_id === 1;
        }
    );
    // }
    
    /*
    const node = document.createElement('link');
    node.href = environment.apiUrl + '/public/assets/templates/v1/email.css';
    node.type = 'text/css';
    node.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(node);
    */
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subjectLine.nativeElement.style.height = '5px';
      const scrollHeight = (this.subjectLine.nativeElement.scrollHeight) + 15;
      this.renderer.setStyle(this.subjectLine.nativeElement, 'height', `${scrollHeight}px`);
    }, 500);
  }

  applyRTLEmbedding() {
    const cursorPosition = this.subjectLine.nativeElement.selectionStart;
    const inputText = this.campaignForm.value.email_subject;
    const convertedText = inputText.slice(0, cursorPosition) + '\u202B' + inputText.slice(cursorPosition);
    this.campaignForm.patchValue({ email_subject: convertedText });
    this.subjectLine.nativeElement.value = convertedText;
  }

  setStatusBar() {
    this.step = {
      title: 'Preview',
      params: {
        projectId: this.campaign.project_id,
        campaignId: this.campaign.id,
        campaignName: this.campaign.name
      }
    };
  }

  customizeFromAddress() {
    const from_address = this.campaignForm.get('email_from').value;
    if (from_address) {
      return;
    }
    this.campaignMeta.email_customized = !this.campaignMeta.email_customized;
    if (!this.campaignMeta.email_customized) {
      this.f.email_from.setValidators([Validators.email]);
      this.campaignForm.patchValue({email_from: ''});
    } else {
      this.f.email_from.setValidators([Validators.email, Validators.required]);
    }
    this.f.email_from.updateValueAndValidity();
  }

  onAddVariablesButtonClick(variablesFor) {
    this.variablesFor = variablesFor;
  }

  onVariableClick(variable, element = null) {

    const value = this.campaignForm.value[this.variablesFor];

    const varValue = variable.key;

    if (element && element.selectionStart || element.selectionStart === '0' || element.selectionStart === 0) {
      const startPos = element.selectionStart;
      const endPos = element.selectionEnd;
      if (element.selectionStart === '0' || element.selectionStart === 0) {
        this.campaignForm.patchValue({[this.variablesFor]: `${value.substring(0, startPos)}${varValue}${value.substring(endPos, value.length)}`});
      } else {
        this.campaignForm.patchValue({[this.variablesFor]: `${value.substring(0, startPos)}${varValue}${value.substring(endPos, value.length)}`});
      }
    } else {
      this.campaignForm.patchValue({[this.variablesFor]: `${value}${varValue}`});
    }
    this.variablesFor = '';
  }

  onButtonVariableClick(variable, textFor) {
    if (textFor === 'buttonText') {
      this.buttonText = `${this.buttonText} ${variable.key}`;
    }
  }

  updateValue(variable, textFor) {
    const value = this.campaignMeta[textFor] || '';
    const varValue = variable.key;
    this.campaignMeta[textFor] = `${value} ${varValue}`;
  }

  selectMailProvider(ev) {
    if(!this.isAdmin) {
      if (ev.value === 'no_email')  {
        this.isTestEmailDisable = true;
        this.campaignForm.controls['email_from_name'].disable();
        this.campaignForm.controls['email_from'].disable();
        this.campaignForm.controls['email_reply_to'].disable();
        this.campaignForm.controls['email_subject'].disable();
        this.campaignForm.controls['emails_source_message'].disable();
      } else {
        this.isTestEmailDisable = false;
        this.campaignForm.controls['email_from_name'].enable();
        this.campaignForm.controls['email_from'].enable();
        this.campaignForm.controls['email_reply_to'].enable();
        this.campaignForm.controls['email_subject'].enable();
        this.campaignForm.controls['emails_source_message'].enable();
      }
    }
    this.campaignForm.patchValue({email_sender_service_provider_slug: ev.value});
    const email_sender_service_provider = this.mailServiceProviders.find(item => item.slug === ev.value);
    if (email_sender_service_provider) {
      this.campaignForm.patchValue({email_sender_service_provider_id: email_sender_service_provider.id});
      this.save();
    }
    this.validateMailAddress();
  }

  subscribeToCampaign() {
    this.templateGeneratorService.onCampaignLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(async campaign => {
        if (!campaign) {
          return;
        }
        campaign = Object.assign({}, campaign);
        this.campaign = campaign;
        this.replaceToProjectLogo();
        this.templateStoreSubscribe();
        this.previousEmail = this.campaign.email_from;
        if (campaign.meta) {
          this.campaignMeta = (typeof campaign.meta === 'string') ? {...JSON.parse(campaign.meta)} : {...campaign.meta};
        }
        if (!this.campaignMeta.hasOwnProperty('widget_active')) {
          this.campaignMeta.widget_active = true;
        }
        if (!this.campaignMeta.hasOwnProperty('share_link_customized')) {
          this.campaignMeta.share_link_customized = false;
        }
        if (!this.campaignMeta.hasOwnProperty('share_link')) {
          this.campaignMeta.share_link = this.defaultShareLink;
        }
        if (!this.campaignMeta.hasOwnProperty('email_customized')) {
          this.campaignMeta.email_customized = false;
        }
        if (!campaign.email_sender_verify_by) {
          campaign.email_sender_verify_by = 'domain';
        }
        this.templateGeneratorService.loadVariables({
          influencerTypeId: this.campaign.segments[0].influencer_type_id,
          campaignId: this.campaign.id
        }, (vars) => {
          if (vars && Object.keys(vars).length) {
            this.variableLoaded = true;
          }
          const result = [];
          Object.entries(vars).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        });

        this.setStatusBar();
        this.applyCampaign(campaign, this.campaign.segments);

        this.influencerStore.dispatch(InfluencersListByProject({
          projectId: this.campaign.project_id,
          campaignId: this.campaign.id
        }));

        this.influencerType = this.campaign.segments[0].influencer_type;

        this.templateGeneratorService.loadClientTemplate({
          templateTypeId: this.template_type_id,
          sourceTemplateId: 2
        }, {createIfNotExist: true, forceTouch: false});
        this.templateGeneratorService.onTemplateChanged[this.template_type_id]
          .pipe(takeUntil(this.unsubscriber))
          .subscribe((template) => {
            if (template && !this.resettingTemplate) {
              this.template = Object.assign({}, template);
              this.templateData = {
                body:  this.template.body,
                body_json:  this.template.body_json,
              }

              if (this.template?.body_json) {
                this.hasJsonBody = true;
                this.editorLoaded = true;
              } else {
                this.hasJsonBody = false;
              }

              if (this.template?.visual_editor) {
                this.editorInfo = this.template.visual_editor;
                this.isOldEditor = this.editorInfo.name === 'old-editor';
              } else {
                this.editorInfo = {
                  name: "old-editor",
                  template_type_id: 2
                };
                this.isOldEditor = true;
              }
              
              if (template.meta) {
                this.templateMeta = (typeof template.meta === 'string') ? JSON.parse(template.meta) : template.meta;
                this.buttonText = this.templateMeta.buttonText || 'I\'m in';
                this.buttonColor = this.templateMeta.buttonColor || this.landingpageButtonColor || '#333399';
                this.buttonTextColor = this.templateMeta?.buttonTextColor || '#ffffff';;
                this.linkColor = this.templateMeta.linkColor || '#6fa8dc';
                this.linkHoverColor = this.templateMeta.linkHoverColor || '#4191ff';
                this.emailBackgroundColor = this.templateMeta.emailBackgroundColor || '#f0fbff';
                this.buttonSpacing = this.templateMeta.buttonSpacing || '';
                this.listSpacing = this.templateMeta.listSpacing || 1;
                this.paragraphSpacing = this.templateMeta.paragraphSpacing || '';
              }
              this.replaceToProjectLogo();
            }
          });
        if (this.campaign.email_from) {
          // this.mailVerifyStore.dispatch(MailVerifySenderStatus({params: {identity: this.campaign.email_from}}));
          this.validateMailAddress();
        }
      });

  }

  templateStoreSubscribe() {
    if (this.campaign.id) {
      const params = {
        campaign_id: this.campaign.id,
        template_type_id: 1
      };
      this.templateService.getAll(params).subscribe(response => {
        if (response['data'].length > 0) {
          this.landingpageButtonColor = response['data'][0].properties.button_color || '#333399' ;
        } else {
          this.landingpageButtonColor = '#333399';
        }
      });
    } else {
      this.landingpageButtonColor = '#333399';
    }
  }

  async validateMailAddress(fromForm: boolean = false, resend = false) {
    //
    // Validate form
    if (this.campaignForm.get('email_from')?.errors?.email === true) return;

    //
    // Apply validation to other fields
    this.customizeFromAddress();

    //
    // Force email customized
    // this.campaignMeta.email_customized = true;
    this.campaignMeta.email_customized = !!this.campaignForm.get('email_from').value;

    //
    // Get email_form and process
    const from_address = this.campaignForm.get('email_from').value;
    // validate
    if (fromForm === true && from_address && from_address !== this.previousEmail && !this.f.email_from.errors) {
      this.previousEmail = from_address;
      // this.campaignForm.patchValue({email_sender_verify_by: null});
    }

    if (from_address) {
      this.toVerifyEmail = true;
      // this.mailVerifyStore.dispatch(MailVerifySenderStatus({params: {identity: from_address}}));
      this.callValidationOnServer(from_address, fromForm, resend);
    }
  }

  callValidationOnServer(identity: string, fromFrom: boolean = false, resend = false) {
    if (this.debounceTimeOut) {
      clearTimeout(this.debounceTimeOut);
    }
    this.debounceTimeOut = setTimeout(async () => {
      await this.validateMailAddressOnServer(identity, fromFrom, resend, this.previousFromEmail !== identity);
      if (this.previousFromEmail !== identity) {
        this.previousFromEmail = identity;
      }
    }, 1000);
  }

  async validateMailAddressOnServer(identity: string, fromFrom: boolean = false, resend = false, resetVerifyBy = false) {
    //
    // Show loading
    this.loaderService.show();

    const verify_by = resetVerifyBy ? null : this.campaignForm.value.email_sender_verify_by;
    const service_provider = this.campaignForm.value.email_sender_service_provider_slug;

    try {
      //
      // Validate email
      const response: any = await this.mailVerifyService.verifySenderStatus({
        identity,
        userId: this.currentUser.id,
        campaignCode: this.campaign.code,
        ...(verify_by ? {verify_by} : {}),
        ...(service_provider ? {service_provider} : {}),
        ...(resend ? {resend} : {})
      }).toPromise();

      // validate
      if (response?.success !== true) throw 'Failed to validate "From Email", try again';
      const data = response.data;

      if (data.showVerificationTypePopup) {
        this.showVerificationStatus('domain');
        this.fromEmailVerified = 'unverified';
        this.loaderService.hide();
        return;
      }

      if (data.showConfigPopup && data?.identity?.verify_by === 'domain' && data?.identity?.verification_status === 'unverified') {
        this.showVerificationStatus();
      }

      if (verify_by === 'email' && data?.identity?.verification_status === 'unverified' && data.mailSent && resend) {
        this.toastr.success('Domain Verification Email has been sent', 'Success');
      }

      //
      // Set status
      const status: 'verified' | 'unverified' = data?.identity?.verification_status === 'verified' ? 'verified' : 'unverified';
      if (status === 'verified' || status === 'unverified') {
        this.fromEmailVerified = status;
      }

      //
      // Show instruction modal if this is not from form
      // if (data?.tokens?.length && data?.identity?.identity && verify_by !== 'email') {
      //   //
      //   // Open modal
      //   const modelRef = this._modalService.open(MailerDnsInfoModalComponent, {
      //     centered: false,
      //     size: 'lg',
      //     keyboard: true,
      //   });
      //
      //   //
      //   // Add data to modal
      //   modelRef.componentInstance.domain = data.identity.identity;
      //   modelRef.componentInstance.tokens = data.tokens;
      //   modelRef.componentInstance.serviceProvider = service_provider;
      //   modelRef.componentInstance.campaignCode = this.campaign.code;
      // }
      // if (this.fromEmailVerified !== 'verified') {
      //   this.showVerificationStatus();
      // }
    } catch (error) {
      this.toastr.error(this.loaderService.getErrorMessage(error), 'Error');
    }

    //
    // Hide loading
    this.loaderService.hide();
  }

  customizeLink() {
    if (!this.campaignMeta['share_link_customized']) {
      this.campaignMeta['share_link'] = this.defaultShareLink;
    }
  }

  onEditorChange({body, body_json, styles}) {
    return this.templateGeneratorService.saveClientTemplate({
      id: this.template.id,
      templateTypeId: this.template_type_id,
      campaignId: this.campaign.id,
      visualEditorName: this.editorInfo.name,
      clientTemplate: {
        body,
        body_json,
        style: styles,
        meta: {
          ...(this.templateMeta ? this.templateMeta : {}),
          buttonText: this.buttonText,
          buttonColor: this.buttonColor,
          buttonTextColor: this.buttonTextColor,
          linkColor: this.linkColor,
          linkHoverColor: this.linkHoverColor,
          emailBackgroundColor: this.emailBackgroundColor,
          buttonSpacing: this.buttonSpacing || '',
          listSpacing: this.listSpacing || 1,
          paragraphSpacing: this.paragraphSpacing || ''
        }
      }
    });
  }

  async getProjectPlanners() {
    this.projectStore.dispatch(ProjectPlanners({projectId: this.campaign.project_id}))
  }

  populatePlannersEmail() {
    if(this.planners.length) {
      let emailIds = this.planners.map(planner => planner.email)
      emailIds = emailIds.filter((email:any) => email !== this.currentUser.email);
      if(emailIds && emailIds.length) {
        for (let i = 0; i < emailIds.length; i++) {
          this.recipients = this.testMailForm.get('recipients') as UntypedFormArray;
          this.recipients.push(this.createRecipient(false, emailIds[i]));
        }
      }
    }
  }

  async onSendTestEmailButtonClick() {
    await this.save();
    const modelRef = this._modalService.open(this.sendTestEmail, {
      centered: true,
      size: 'md',
      keyboard: true,
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
    this.getProjectPlanners();
    this.initTestForm();
  }

  async sendTestEmails(modal: any) {
    this.submitted = true;
    if(this.testMailForm.get('recipients').invalid) {
      this.toastr.error('Please enter valid email address', 'Error');
      return;
    }
    const recipients = this.testMailForm.get('recipients').value.map((val) => {
      if (val.email) {
        return val.email;
      } else {
        return;
      }
    });

    if (recipients.length < 1) {
      this.toastr.error('Please add recipients', 'Error');
      return;
    }
    if (this.campaignForm.invalid) {
      modal.close();
      return;
    }
    if (recipients) {
        if (this.sampleInfluencerEmail) {
          if (!this.validateEmail(this.sampleInfluencerEmail)) {
            this.toastr.error('Please enter valid email address', 'Error');
            return;
          }
        }
        if (this.campaignForm.get('email_from').value && this.fromEmailVerified && this.fromEmailVerified === 'unverified') {
          modal.close();
          this.toastr.warning('Please verify your email', 'Warning');
          return;
        }
        await this.save();
        modal.close();
        this.emailPreviewClicked = true;
        if (this.additionalContent) {
          this.additionalContent = `<div class="col-lg-7" style="background: white;
                                    border: 3px solid #4bb7e3;">
                                    <div style="width: 70%;
                                    margin: 0 auto;
                                    padding: 16px 10px;
                                    font-family: 'omnesregular',Helvetica;
                                    color: #202020 !important;
                                    font-size: 14px;">` + this.additionalContent + `</div></div>`;
        }
        this.templateGeneratorService.sendTestEmail(recipients, this.sampleInfluencerEmail, this.additionalContent);
        this.testMailForm.reset();
        this.sampleInfluencerEmail = '';
        this.isIncludeContent = false;
        this.additionalContent = '';

    } else {
      this.toastr.error('Please add recipients', 'Error');
      return;
    }
  }

  validateEmail(email) {
    const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    const emailParts = email.split('@');

    if (emailParts.length !== 2) {
      return false;
    }

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length > 64) {
      return false;
    } else if (address.length > 255) {
      return false;
    }

    const domainParts = address.split('.');
    if (domainParts.some(function (part) {
      return part.length > 63;
    })) {
      return false;
    }

    return tester.test(email);
  }

  async save() {
    if (!!this.templateData.body_json) {
      const template = {
        body: this.templateData.body,
        body_json: JSON.stringify(this.templateData.body_json),
        styles: null
      }
      this.onEditorChange(template);
    } else {
      const template: any = this.editor.buildUpdatedTemplate();
      this.onEditorChange({ ...template, body_json: null});
    }
    
    let campaign = this.campaignForm.value;
    delete campaign.segment;
    campaign = {
      ...campaign,
      meta: {
        ...campaign.meta,
        ...this.campaignMeta
      }
    };
    // this.campaignStore.dispatch(CampaignUpdate({campaign, campaignId: this.campaign.id}));
    this.loaderService.show();
    return await this.campaignService.updateCampaign(campaign, this.campaign.id).pipe(
      map((resp: any) => {
        if (resp.success) {
          this.loaderService.hide();
          return this.campaignStore.dispatch(CampaignUpdateSuccess({campaign: resp.data}));
        }
        this.loaderService.hide(true);
        return this.campaignStore.dispatch(CampaignUpdateError({error: this.loaderService.getErrorMessage(resp)}));
      }),
      catchError(error => {
        this.loaderService.hide(true);
        return of(this.campaignStore.dispatch(CampaignUpdateError({error: this.loaderService.getErrorMessage(error)})));
      })
    ).toPromise();
  }

  async shareWidgetPreview() {
    this.submitted = true;
    if (this.campaignForm.invalid) {
      return;
    }
    this.previewWidget = true;
    await this.save();
  }

  async onNextButtonClick(exit = false) {
    if (!this.campaignForm.value.email_sender_verify_by) {
      this.toastr.warning('Please select verification method to proceed!', 'Warning');
      return;
    }
    this.submitted = true;
    if (this.campaignForm.invalid) {
      return;
    }
    this.nextButtonClicked = true;
    this.isExit = exit;
    await this.save();
  }

  get f() {
    return this.campaignForm.controls;
  }

  onSaveButtonClick() {
    this.onNextButtonClick(true);
  }

  onBackButtonClick() {
    this.router.navigateByUrl(
      routes.campaignShare({
        projectId: this.campaign.project_id,
        campaignId: this.campaign.id
      })
    );
  }

  showTemplateRevisions() {
    const modelRef: any = this._modalService.open(TemplateRevisionsComponent, {centered: true, keyboard: true, size: 'xl'});
    modelRef.componentInstance.templateId = this.template.id;
    modelRef.componentInstance.canReset = false;
    modelRef.result.then((result) => {
      if (result.data) {
        result.data = Object.assign({}, result.data);
        if (result.data.meta) {
          result.data.meta = (typeof result.data.meta === 'string') ? JSON.parse(result.data.meta) : result.data.meta;
          this.templateMeta = result.data.meta;
          this.template = null;
          setTimeout(() => {
            this.template = result.data;
            this.templateData = {
              body:  this.template.body,
              body_json:  this.template.body_json,
            }

            this.buttonText = this.templateMeta.buttonText || 'I\'m in';
            this.buttonColor = this.templateMeta.buttonColor || '#333399';
            this.buttonTextColor = this.templateMeta?.buttonTextColor || '#ffffff';;
            this.linkColor = this.templateMeta.linkColor || '#3b3e66';
            this.linkHoverColor = this.templateMeta.linkHoverColor || '#4191ff';
            this.emailBackgroundColor = this.templateMeta.emailBackgroundColor || '#f0fbff';
            this.buttonSpacing = this.templateMeta.buttonSpacing || '';
            this.paragraphSpacing = this.templateMeta.paragraphSpacing || '';
            this.listSpacing = this.templateMeta.listSpacing || '';
            this.replaceToProjectLogo();
          }, 200);
        }
      }
    }, (reason) => {
    });
  }


  openEditorFullscreen(campaignId, influencerTypeId, token, isPreview, editorUrl) {
    const existingIframe = document.getElementById('editorIframe');
    if (existingIframe) {
        existingIframe.remove();
    }

    const iframe = document.createElement('iframe');
    iframe.id = 'editorIframe';
    iframe.src = `${editorUrl}?campaignId=${campaignId}&influencerTypeId=${influencerTypeId}&token=${token}&isPreview=${isPreview}`;
    
    let firstElement = document.body.firstElementChild;
    document.body.insertBefore(iframe, firstElement);
  }  

  showNewEditor() {
    const editorName = this.isOldEditor ? 'email-builder-js' : this.editorInfo.name;
    this.showEditor[editorName] = true;
  }

  resetTemplate() {
    Swal({
      title: 'This action will reset current template. Are you sure you want to continue?',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Close',
      width: '55%'
    }).then(result => {
      if (result.value) {
        if (this.template.source_template_id) {
          this.resettingTemplate = true;
          this.templateStore.dispatch(ResetTemplateState({params: {template: null}}));
          this.templateStore.dispatch(TemplateGet({templateId: this.template.source_template_id}));
        }
      }
    });
  }

  applyCampaign(campaign, segment) {
    if(campaign.service_provider_slug && campaign.service_provider_slug === "no_email" && !this.isAdmin) {
      this.isTestEmailDisable = true;
      this.campaignForm.controls['email_from_name'].disable();
      this.campaignForm.controls['email_from'].disable();
      this.campaignForm.controls['email_reply_to'].disable();
      this.campaignForm.controls['email_subject'].disable();
      this.campaignForm.controls['emails_source_message'].disable();
    }
    this.campaignForm.patchValue({
      email_from_name: campaign.email_from_name || '',
      email_from: campaign.email_from || '',
      email_reply_to: campaign.email_reply_to || 'no-reply@snoball.it',
      email_subject: campaign.email_subject || '#InfluencerFName#, why not invite others to #ProjectTitle#?',
      emails_source_message: campaign.emails_source_message || '',
      segment: segment[0].name || '',
      email_sender_verify_by: campaign.email_sender_verify_by,
      email_sender_service_provider_id: campaign.email_sender_service_provider_id || null,
      email_sender_service_provider_slug: campaign.service_provider_slug || 'aws_ses'
    });
    this.previousFromEmail = campaign.email_from || '';
  }

  initializeCampaignForm() {
    this.campaignForm = this.formBuilder.group({
      email_from_name: new UntypedFormControl('', [Validators.required]),
      email_from: new UntypedFormControl('', [Validators.email]),
      email_reply_to: new UntypedFormControl('no-reply@snoball.it', [Validators.required, Validators.email]),
      email_subject: new UntypedFormControl('#InfluencerFName#, why not invite others to #ProjectTitle#?', [Validators.required]),
      emails_source_message: new UntypedFormControl('', []),
      segment: new UntypedFormControl('', [Validators.required]),
      email_sender_verify_by: new UntypedFormControl('', [Validators.required]),
      email_sender_service_provider_id: new UntypedFormControl(null, []),
      email_sender_service_provider_slug: new UntypedFormControl(null, []),
    });
    const emailAddress = this.campaignForm.get('email_from');
    emailAddress.valueChanges.subscribe(() => {
      emailAddress.patchValue(emailAddress.value.toLowerCase(), {emitEvent: false});
    });
  }

  previewEmailTemplate() {
    this.templateGeneratorService.sendTestEmail('no-reply@snoball.it');
  }

  autoGrow(element) {
    element.style.height = '5px';
    element.style.height = (element.scrollHeight + 15) + 'px';
  }

  showVerificationStatus(change: string = null) {
    if (!this.campaignForm.value.email_sender_verify_by || change) {
      const modelRef: any = this._modalService.open(VerificationByComponent, {centered: true, keyboard: true, size: 'lg'});
      modelRef.componentInstance.fromEmail = this.campaignForm.value.email_from;
      modelRef.componentInstance.preSelect = change;
      modelRef.result.then((result) => {
        if (result) {
          this.campaignForm.patchValue({email_sender_verify_by: result});
          this.save();
          this.showVerificationStatus();
        }
      }, (reason) => {
      });
    } else {
      const modelRef: any = this._modalService.open(VerificationComponent, {centered: true, keyboard: true, size: 'lg'});
      modelRef.componentInstance.fromEmailVerified = this.fromEmailVerified;
      modelRef.componentInstance.emailSenderVerifyBy = this.campaignForm.value.email_sender_verify_by;
      modelRef.componentInstance.fromEmail = this.campaignForm.value.email_from;
      modelRef.componentInstance.emailSenderServiceProviderSlug = this.campaignForm.value.email_sender_service_provider_slug;
      modelRef.componentInstance.mailServiceProviders = this.mailServiceProviders;
      modelRef.componentInstance.campaignCode = this.campaign.code;
      modelRef.componentInstance.currentUser = this.currentUser;
      modelRef.componentInstance.mode = this.mode;
      modelRef.result.then((result) => {
        if (result) {
          if (typeof result !== 'string' && result.change) {
            // this.campaignForm.patchValue({email_sender_verify_by: null});
            this.showVerificationStatus(result.change);
          } else {
            this.campaignForm.patchValue({email_sender_service_provider_slug: result});
            const email_sender_service_provider = this.mailServiceProviders.find(item => item.slug === result);
            if (email_sender_service_provider) {
              this.campaignForm.patchValue({email_sender_service_provider_id: email_sender_service_provider.id});
              this.save();
            }
            this.validateMailAddressOnServer(this.campaignForm.value.email_from);
          }
        }
      }, (reason) => {
      });
    }
  }

  initTestForm(): void{
    this.testMailForm = this.formBuilder.group({
      timezone: null,
      recipients: this.formBuilder.array([ this.createRecipient(true) ])
    });
  }

  createRecipient(isFirst: boolean, email=''): UntypedFormGroup {
    let emailValue = (isFirst && this.currentUser?.email) ? this.currentUser.email : email;
    return this.formBuilder.group({
      email: [emailValue,[Validators.required,Validators.email]],
    });
  }

  addRecipient(): void {
    this.recipients = this.testMailForm.get('recipients') as UntypedFormArray;
    this.recipients.push(this.createRecipient(false));
  }

  removeRecipient(recipient): void {
    this.recipients = this.testMailForm.get('recipients') as UntypedFormArray;
    this.formLenght$.next(this.recipients.length)
    if(this.recipients.length === 1) {
      return;
    }
    this.recipients.removeAt(recipient);
  }

  onEditorStateChanged(event, editorName) {
    if (editorName == 'email-builder-js') {
      if (event.action === 'save') {
        this.templateData = {
          body: event.body,
          body_json: JSON.parse(event.body_json)
        }
        this.hasJsonBody = true;
        this.isOldEditor = false;
        this.editorInfo.name = 'email-builder-js';
      }

      this.showEditor['email-builder-js'] = false;
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.templateGeneratorService.unsubscribe();
  }

  protected readonly project = project;
}
