import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as routes from '@app/routes';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { LoaderService } from '@app/services/loader.service';
import { select, Store } from '@ngrx/store';
import {
  getCampaignError,
  getInfluencerError,
  getInfluencers,
  ICampaignState,
  IInfluencersState,
  IMailVerifyState,
  InfluencersListByProject,
  CampaignValidation,
  CampaignValidationSuccess,
  getCampaignValidation
} from '@app/stores';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchScheduleModalComponent } from '../launch-schedule-modal/launch-schedule-modal.component';
import * as moment from 'moment-timezone';
import {Constants, smartDateFormat} from '@app/consts';
import { find, isEmpty } from 'lodash';
import { BehaviorSubjectHelper } from '@app/classes/behavior-subject-helper';
import { CampaignService } from '@app/services/campaign.service';
import { TemplateGeneratorService } from '@app/services/template-generator.service';
import { MailVerifyService } from '@app/services/mail-verify.service';
import { MailerDnsInfoModalComponent } from '@app/modules/mailer-dns-info-modal/mailer-dns-info-modal.component';
import { LaunchConfirmationModalComponent } from '@app/modules/launch-confirmation-modal/launch-confirmation-modal.component';
import {InfluencerApiService} from '@app/services/api/influencer-api.service';
import {VerificationComponent} from "@app/_components/verification/verification.component";

//
// @TODO: add this in a separate file to reuse
interface ScheduleDate {
  schedule_date?: string;
  schedule_time?: string;
  timezone?: any;
  timezoneValue?: any;
  timezoneText?: any;
}

@Component({
  selector: 'app-launch',
  templateUrl: './launch-campaign.component.html',
  styleUrls: ['./launch-campaign.component.scss'],
  providers: [Constants],
})
export class LaunchCampaignComponent implements OnInit, OnDestroy {
  public campaign: BehaviorSubjectHelper<any> = new BehaviorSubjectHelper<any>({});
  public step;
  public influencerCount: any;
  public newInfluencerCount: any;
  public campaignEmailInfluencerCount: any;
  routes = routes;

  public doTheSegmentHaveInfluencers: Boolean;
  public doTheSegmentHaveInfluencersLoading: Boolean;
  public isCampaignTemplateSet: Boolean;
  public isCampaignTemplateSetLoading: Boolean;
  public isLandingPageSet: Boolean;
  public isLandingPageSkipped: Boolean;
  public isLandingPageSetLoading: Boolean;
  public isSocialPageSet: Boolean;
  public isSocialPageSetLoading: Boolean;
  public launchStatus: string;
  public influencerType: String;
  public showEmailCustomize = false;
  public schedule: ScheduleDate = {};
  unsubscriber = new Subject();
  loader$: Observable<boolean>;
  public campaignMeta: any = {};
  resendEmailClick = false;
  timeZoneValues = [];
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  projectId: string = '';
  campaignId: string = '';
  launchType: string = '';
  public allow_campaign_to_launch_when_registration_widget_is_disabled: Boolean;
  public isWidgetActive: Boolean;
  public isWidgetPublicAccess: Boolean;
  public widget_Settings;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private influencerStore: Store<IInfluencersState>,
    private campaignStore: Store<ICampaignState>,
    private mailVerifyStore: Store<IMailVerifyState>,
    private readonly _modalService: NgbModal,
    private readonly constants: Constants,
    private readonly campaignService: CampaignService,
    private influencersApiService: InfluencerApiService,
    private readonly route: ActivatedRoute,
    private readonly templateGeneratorService: TemplateGeneratorService,
    private readonly mailVerifyService: MailVerifyService
  ) {
    this.doTheSegmentHaveInfluencers = false;
    this.doTheSegmentHaveInfluencersLoading = true;
    this.isCampaignTemplateSet = false;
    this.isCampaignTemplateSetLoading = true;
    this.isLandingPageSet = false;
    this.isLandingPageSkipped = false;
    this.isLandingPageSetLoading = true;
    this.isSocialPageSet = false;
    this.isSocialPageSetLoading = true;
    this.launchStatus = null;
    this.influencerCount = 0;
    this.newInfluencerCount = 0;
    this.campaignEmailInfluencerCount = 0;
    this.timeZoneValues = constants.timezone.map(x => x.value);
    this.campaignStore.dispatch(CampaignValidationSuccess(null));
    this.subscribeStore();
    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      if (queryParams.type) {
        this.launchType = queryParams.type;
      }
    });
    this.projectId = this.route.snapshot.parent.params.projectId;
    this.campaignId = this.route.snapshot.parent.params.campaignId;
  }

  subscribeStore() {
    // this.influencerStoreSubscribe();
    this.campaignStoreSubscribe();
  }

  campaignStoreSubscribe() {
    this.campaignStore
      .pipe(select(getCampaignError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((error) => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.campaignStore
      .pipe(select(getCampaignValidation))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data) => {
        if (data) {
          this.influencerCount = data.influencerCount;
          this.newInfluencerCount = data.newInfluencerCount;
          this.campaignEmailInfluencerCount = data.campaignEmailInfluencerCount;
          this.influencerType = data.influencerType;
          this.doTheSegmentHaveInfluencers = data.doTheSegmentHaveInfluencers;
          this.isLandingPageSet = data.isLandingPageSet;
          this.isLandingPageSkipped = data.isLandingPageSkipped;
          this.isSocialPageSet = data.isSocialPageSet;
          this.showEmailCustomize = data.showEmailCustomize;
          this.isCampaignTemplateSet = data.isCampaignTemplateSet;
          this.campaign.set(data.campaign);
          this.campaignMeta = {};

          if (data.campaign.status !== 'draft') {
            this.launchStatus = 'done';
          }
          if (data.campaign.meta) {
            this.campaignMeta = typeof data.campaign.meta === 'string' ? {...JSON.parse(data.campaign.meta)} : {...data.campaign.meta};
          }
          this.allow_campaign_to_launch_when_registration_widget_is_disabled = this.campaignMeta.allow_campaign_to_launch_when_registration_widget_is_disabled;
          this.widget_Settings = typeof data.campaign.project.widget_settings === 'string' ? {...JSON.parse(data.campaign.project.widget_settings)} : {...data.campaign.project.widget_settings};
          if(this.widget_Settings.active) {
            this.isWidgetActive = true;
            if(this.widget_Settings.whitelist_access) {
              this.isWidgetPublicAccess = true;
            }
          }
          this.doTheSegmentHaveInfluencersLoading = false;
          this.isCampaignTemplateSetLoading = false;
          this.isLandingPageSetLoading = false;
          this.isSocialPageSetLoading = false;
          this.setStatusBar();
        }
      });

    // this.campaignStore.pipe(select(getCampaignLaunch))
    //   .pipe(takeUntil(this.unsubscriber))
    //   .subscribe(campaign => {
    //     if (campaign) {
    //       if (campaign.status !== 'draft') {
    //         //
    //         // Update campaign object
    //         this.campaign.set(campaign);
    //         // update page status and status bar
    //         this.launchStatus = 'done';
    //         this.setStatusBar();
    //       }
    //     }
    //   });
  }

  // influencerStoreSubscribe() {
  //   this.influencerStore
  //     .pipe(select(getInfluencerError))
  //     .pipe(takeUntil(this.unsubscriber))
  //     .subscribe((error) => {
  //       if (error) {
  //         this.toastr.error(error, 'Error');
  //       }
  //     });
  //   this.influencerStore
  //     .pipe(select(getInfluencers))
  //     .pipe(takeUntil(this.unsubscriber))
  //     .subscribe((influencers) => {
  //       if (influencers && influencers.length > 0) {
  //         this.filterInfluencers(influencers);
  //       } else {
  //         this.influencerCount = 0;
  //         this.doTheSegmentHaveInfluencers = false;
  //         this.doTheSegmentHaveInfluencersLoading = false;
  //       }
  //     });
  // }

  /*influencerStoreSubscribe() {
    if (this.campaign.value?.id) {
      const params = {
        project_id: this.campaign.value?.project_id,
        campaign_id: this.campaign.value?.id,
        project_influencer_segment_id: this.campaign.value?.segments[0].id
      };
      this.influencersApiService.getInfluencerCount(params).subscribe(response => {
        if (response.body.success) {
          if (response.body.data > 0) {
            this.influencerCount = response.body.data;
            this.doTheSegmentHaveInfluencers = this.influencerCount > 0;
            this.doTheSegmentHaveInfluencersLoading = false;
          } else {
            this.influencerCount = 0;
            this.doTheSegmentHaveInfluencers = false;
            this.doTheSegmentHaveInfluencersLoading = false;
          }
        }
      });
    } else {
      setTimeout(() => {
        this.influencerStoreSubscribe();
      }, 1000);
      return;
    }
  }*/

  // filterInfluencers(influencers) {
  //   if (this.campaign.value?.id) {
  //     const segment = this.campaign.value?.segments[0];
  //     const filteredInfluencers = influencers.filter((influencer) => {
  //       return influencer.project_influencer_segment_id === segment.id;
  //     });
  //     this.influencerCount = filteredInfluencers.length;
  //     this.doTheSegmentHaveInfluencers = this.influencerCount > 0;
  //     this.doTheSegmentHaveInfluencersLoading = false;
  //   } else {
  //     setTimeout(() => {
  //       this.filterInfluencers(influencers);
  //     }, 1000);
  //     return;
  //   }
  // }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    this.campaignStore.dispatch(CampaignValidation({
      project_id: this.route.snapshot.parent.params.projectId,
      campaign_id: this.route.snapshot.parent.params.campaignId,
    }));
    // this.setCampaign();
  }

  setStatusBar() {
    this.step = {
      title: this.launchType !== 'relaunch' ? 'Launch' : 'ReLaunch',
      params: {
        projectId: this.campaign.value?.project_id,
        campaignId: this.campaign.value?.id,
        campaignName: this.campaign.value?.name,
        isLaunched: this.launchStatus === 'done' && this.launchType !== 'relaunch',
      },
    };
  }

  async setCampaign() {
    try {
      //
      // Get campaign
      const campaignFromNetwork: any = await this.campaignService
        .getCampaign(this.route.snapshot.parent.params.campaignId, {
          options: JSON.stringify({ include_segments: true, include_project: true }),
        })
        .toPromise();

      //
      // Validate campaign
      if (!campaignFromNetwork?.data?.id) throw 'Campaign not found';
      // set campaign
      const campaign: any = campaignFromNetwork.data;
      // unset loading
      this.loaderService.hide();

      //
      // Set campaign entry
      this.campaign.set(campaign);

      //
      // Set campaign status
      if (campaign.status !== 'draft') {
        this.launchStatus = 'done';
      }

      //
      // Set meta
      if (campaign.meta) {
        this.campaignMeta = typeof campaign.meta === 'string' ? { ...JSON.parse(campaign.meta) } : { ...campaign.meta };
      }

      //
      // Set customized email
      if (!this.campaignMeta.hasOwnProperty('email_customized')) {
        this.campaignMeta.email_customized = false;
      }

      //
      // Validate campaign segments
      if (!this.campaign.value?.segments?.length) {
        this.router.navigate([`/project/${this.campaign.value?.project_id}/add-influencer`], {
          queryParams: { campId: this.campaign.value?.id },
        });
        return;
      }

      // this.influencerStore.dispatch(
      //   InfluencersListByProject({ projectId: this.campaign.value?.project_id, campaignId: this.campaign.value?.id,
      //     options: JSON.stringify({
      //       onlyCount: true
      //     })
      //   })
      // );

      this.influencerType = this.campaign.value?.segments[0].influencer_type.name;
      this.isLandingPageSet = true;
      this.isSocialPageSet = true;
      if (this.campaign.value?.email_from && this.campaignMeta.email_customized) {
        this.showEmailCustomize = true;
        this.validateMailAddressOnServer();
        // this.mailVerifyStore.dispatch(MailVerifySenderStatus({ params: { identity: this.campaign.value?.email_from } }));
      }
      this.isLandingPageSetLoading = false;
      this.isSocialPageSetLoading = false;
      this.setStatusBar();
    } catch (error) {
      console.log('error', error);
      this.loaderService.hide();
      this.toastr.error(error, 'Error');

      if (error === 'Campaign not found') this.router.navigate([`/projects/${this.route.snapshot.parent.params.projectId}/campaigns`]);
    }
  }

  // subscribeToCampaign() {
  //   this.templateGeneratorService.onCampaignLoaded
  //     .pipe(takeUntil(this.unsubscriber))
  //     .subscribe(async campaign => {
  //       if (!campaign) {
  //         return;
  //       }

  //       this.campaign.set(campaign);
  //       console.log('campaign.status', campaign.status);
  //       if (campaign.status !== 'draft') {
  //         this.launchStatus = 'done';
  //       }
  //       if (campaign.meta) {
  //         this.campaignMeta = (typeof campaign.meta === 'string') ? { ...JSON.parse(campaign.meta) } : { ...campaign.meta };
  //       }
  //       if (!this.campaignMeta.hasOwnProperty('email_customized')) {
  //         this.campaignMeta.email_customized = false;
  //       }
  //       if (!this.campaign.value?.segments.length) {
  //         this.router.navigate([`/project/${this.campaign.value?.project_id}/add-influencer`], { queryParams: { campId: this.campaign.value?.id } });
  //         return;
  //       }

  //       this.influencerStore.dispatch(InfluencersListByProject({ projectId: this.campaign.value?.project_id, campaignId: this.campaign.value?.id }));

  //       this.influencerType = this.campaign.value?.segments[0].influencer_type.name;
  //       this.isLandingPageSet = true;
  //       this.isSocialPageSet = true;
  //       if (this.campaign.value?.email_from && this.campaignMeta.email_customized) {
  //         this.showEmailCustomize = true;
  //         this.mailVerifyStore.dispatch(MailVerifySenderStatus({ params: { identity: this.campaign.value?.email_from } }));
  //       }
  //       this.isLandingPageSetLoading = false;
  //       this.isSocialPageSetLoading = false;
  //       this.setStatusBar();
  //     });
  // }

  redirect(type) {
    switch (type) {
      case 'template':
        return this.router.navigateByUrl(
          routes.campaignTemplate({
            projectId: this.projectId,
            campaignId: this.campaignId,
          })
        );
      case 'socialShare':
        return this.router.navigate([`/projects/${this.projectId}/campaigns/${this.campaignId}/share`]);
      case 'statistics':
        return this.router.navigate([`/projects/${this.projectId}/statistics`], {
          queryParams: { campaignId: this.campaignId },
        });
      case 'influencer':
        return this.router.navigate([`/project/${this.projectId}/add-influencer`], {
          queryParams: { campId: this.campaignId },
        });
      case 'email':
        return this.router.navigateByUrl(
          routes.campaignPreview({
            projectId: this.projectId,
            campaignId: this.campaignId,
          })
        );
      case 'widget':
        return this.router.navigate([`/projects/${this.projectId}/widget`])
    }
  }

  async launchCampaign(code: string, options: any = {}) {
    //
    // Start launch
    this.launchStatus = 'start';

    try {
      //
      // Launch campaign
      const campaignLaunch: any =
        options.schedule && options.timezone
          ? await this.campaignService
              .launchScheduled(this.campaign.value?.id, options.schedule, options.timezone, code, this.launchType === 'relaunch')
              .toPromise()
          : await this.campaignService.launch(this.campaign.value?.id, code , this.launchType === 'relaunch').toPromise();

      // validate
        if (!campaignLaunch.success || !campaignLaunch?.data?.id) {
        this.loaderService.hide(true);
        return this.toastr.error(this.loaderService.getErrorMessage(campaignLaunch), 'Error');
      }

      //
      // Update campaign if this is not a draft
      if (campaignLaunch?.data?.status !== 'draft') {
        //
        // Update campaign object
        this.launchType = '';
        this.campaign.set(campaignLaunch?.data);
        // update page status and status bar
        this.launchStatus = 'done';
        this.setStatusBar();
        this.launchCampaignRoute(this.campaignId);
      }
    } catch (error) {
      //
      // Start launch
      this.launchStatus = null;

      this.loaderService.hide(true);
      return this.toastr.error(this.loaderService.getErrorMessage(error), 'Error');
    }
  }

  async onSaveButtonClick() {
    this.router.navigateByUrl('/' + routes.campaigns({ projectId: this.campaign.value?.project_id }));
  }

  resendMail() {
    this.resendEmailClick = true;
    // this.mailVerifyStore.dispatch(MailVerifySender({params: {identity: this.campaign.value?.email_from}}));
    this.validateMailAddressOnServer(true);
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    // this.templateGeneratorService.unsubscribe();
  }

  openLaunchScheduleModal(data: ScheduleDate = {}) {
    //
    // Unset loader
    this.loader$ = null;

    //
    // Open modal
    const modelRef = this._modalService.open(LaunchScheduleModalComponent, {
      centered: false,
      // size: 'sm',
      keyboard: true,
      windowClass: 'schedule-modal',
    });

    //
    // add current project.timezone to data.timezone if available and not set yet
    if (!data?.timezone && this.campaign.value?.project?.timezone) data.timezone = this.campaign.value?.project.timezone;

    //
    // Add current schedule dates
    modelRef.componentInstance.schedule = data;
    if (this.launchType === 'relaunch') {
      modelRef.componentInstance.launchType = this.launchType;
    }

    //
    // On close modal event
    // should validate and update schedule date
    modelRef.result.then((result: ScheduleDate = {}) => {
      //
      // Validate result
      if (!result?.schedule_date || !result?.schedule_time) return;
      // update schedule date
      this.schedule = { ...result };

      this.openLaunchConfirmationModal();
    });
  }

  openLaunchScheduleConfirmationModal() {
    //
    // Unset loader
    this.loader$ = null;

    //
    // Open modal
    const modelRef = this._modalService.open(LaunchConfirmationModalComponent, {
      centered: false,
      size: 'md',
      keyboard: true,
    });

    //
    // Add current schedule dates
    modelRef.componentInstance.schedule = this.schedule;
    modelRef.componentInstance.currentUser = this.currentUser;
    modelRef.componentInstance.timeZoneValues = this.timeZoneValues;
    modelRef.componentInstance.campaignID = this.campaign.value?.id;
    if (this.launchType === 'relaunch') {
      modelRef.componentInstance.launchType = this.launchType;
    }
    modelRef.componentInstance.onClearSchedule.subscribe(data => {
      this.schedule = data;
    });

    //
    // On close modal event
    // should validate and update schedule date
    modelRef.result.then((result: { action: 'edit' | 'launch' | 'schedule' | 'schedule-launch' | 'clear' | 'launch-now', code?: string }): any => {
      //
      // action = edit: open edit dialog
      if (result.action === 'edit') {
        return this.openLaunchScheduleModal(this.schedule);
      } else if (result.action === 'schedule-launch' && result?.code) {
        return this.launchCampaign(result.code, { schedule: this.schedule.schedule_date + ' ' + this.schedule.schedule_time, timezone: this.schedule?.timezone });
      } else if (result.action === 'launch' && result?.code) {
        this.schedule = {};
        return this.launchCampaign(result.code);
      } else if (result.action === 'launch-now') {
        return this.launchCampaign(result.code);
      } else if (result.action === 'clear') {
        this.schedule = {};
        return true;
      } else if (result.action === 'schedule') {
        this.schedule = {};
        return this.launchScheduledCampaign();
      }
    });
  }

  openLaunchConfirmationModal() {
    //
    // Unset loader
    this.loader$ = null;

    if (!isEmpty(this.schedule)) {
      return this.openLaunchScheduleConfirmationModal();
    }

    //
    // Open modal
    const modelRef = this._modalService.open(LaunchConfirmationModalComponent, {
      centered: false,
      // size: 'sm',
      keyboard: true,
    });

    //
    // Set schedule as empty
    // modelRef.componentInstance.schedule = {};
    modelRef.componentInstance.schedule = this.schedule || {};
    modelRef.componentInstance.campaignID = this.campaign.value?.id;
    if (this.launchType === 'relaunch') {
      modelRef.componentInstance.launchType = this.launchType;
    }
    //
    // On close modal event
    // should validate and update schedule date
    modelRef.result.then((result: { action: 'cancel' | 'launch' | 'schedule', code?: string }): any => {
      //
      // action = edit: open edit dialog
      if (result.action === 'cancel' || !result?.code) return;
      // action  = launch: should launch the scheduled campaign
      else if (result.action === 'launch') {
        return this.launchCampaign(result.code);
      } else if (result.action === 'schedule') {
        return this.launchScheduledCampaign();
      } else if (result.action === 'edit') {
        this.schedule = {};
      }
    });
  }

  launchScheduledCampaign() {
    //
    // If schedule is not setup, open schedule dialog
    if (!this.schedule?.schedule_date || !this.schedule?.schedule_time) return this.openLaunchScheduleModal();
    // filled, open options dialog
    else return this.openLaunchScheduleConfirmationModal();
  }

  getScheduleDateTime(actual = false) {
    return smartDateFormat(this.schedule?.schedule_date + ' ' + this.schedule?.schedule_time, this.schedule?.timezone, null, actual ? 'fullDateTimeWithTimeZone' : 'fullDateTime', actual ? this.schedule?.timezone : null);
  }

  getScheduleDateTimeCustom(date, customTz = null, actual = false) {
    console.log(date, customTz, actual);
    return smartDateFormat(date, customTz, null, actual ? 'fullDateTimeWithTimeZone' : 'fullDateTime', actual ? this.schedule?.timezone : null);
  }

  getLaunchedMessage() {
    //
    // Default message
    let value = `Your ${this.campaign.value?.name} campaign is now launched.`;

    // message for campaign in queue
    if (this.campaign.value?.status === 'launch_queued') {
      value = `Your ${this.campaign.value?.name} campaign is now in queue.`;
    }
    // message for campaign failed
    if (this.campaign.value?.status === 'launch_failed') {
      value = `Your ${this.campaign.value?.name} campaign has failed to launch.`;
    }

    // Change value for scheduled
    if (this.campaign.value?.status === 'launch_scheduled') {
      value = `Your ${this.campaign.value?.name} campaign is scheduled to launch at ${this.getScheduleDateTimeCustom(this.campaign.value?.launch_scheduled_at, this.campaign.value?.timezone, false)}.`;
    }

    return value;
  }

  getCampaignTZDate() {
    return smartDateFormat(this.campaign.value?.launch_scheduled_at, null, null, 'fullDateTimeWithTimeZone', this.campaign.value?.timezone);
  }

  async validateMailAddressOnServer(showModal: boolean = false) {
    //
    // Validate email_form
    if (!this.campaign.value?.email_from) {
      this.isCampaignTemplateSetLoading = false;
      this.isCampaignTemplateSet = false;
    }

    //
    // Show loading
    this.loaderService.show();
    this.isCampaignTemplateSetLoading = true;
    this.isCampaignTemplateSet = false;

    try {
      //
      // Validate email
      const response: any = await this.mailVerifyService.verifySenderStatus({ identity: this.campaign.value?.email_from }).toPromise();

      // validate
      if (response?.success !== true) throw 'Failed to validate "From Email", try again';
      const data = response.data;

      //
      // Set status
      const status: 'verified' | 'unverified' = data?.identity?.verification_status === 'verified' ? 'verified' : 'unverified';
      this.isCampaignTemplateSet = status === 'verified' ? true : false;

      // //
      // // Show instruction modal if this is not from form
      // if (showModal === true && data?.tokens?.length && data?.identity?.identity) {
      //   //
      //   // Open modal
      //   const modelRef = this._modalService.open(MailerDnsInfoModalComponent, {
      //     centered: false,
      //     size: 'lg',
      //     keyboard: true,
      //   });
      //
      //   //
      //   // Add data to modal
      //   modelRef.componentInstance.domain = data.identity.identity;
      //   modelRef.componentInstance.tokens = data.tokens;
      //   modelRef.componentInstance.campaignCode = this.campaign.value.code;
      // }

      const modelRef: any = this._modalService.open(VerificationComponent, {centered: true, keyboard: true, size: 'lg'});
      modelRef.componentInstance.fromEmailVerified = status;
      modelRef.componentInstance.emailSenderVerifyBy = this.campaign.value?.email_sender_verify_by;
      modelRef.componentInstance.fromEmail = this.campaign.value?.email_from;
      modelRef.componentInstance.emailSenderServiceProviderSlug = this.campaign.value?.service_provider_slug;
      modelRef.componentInstance.mailServiceProviders = [];
      modelRef.componentInstance.campaignCode = this.campaign.value?.code;
      modelRef.componentInstance.currentUser = this.currentUser;
      modelRef.result.then((result) => {
      }, (reason) => {
      });
    } catch (error) {
      this.toastr.error(this.loaderService.getErrorMessage(error), 'Error');
    }

    //
    // Hide loading
    this.loaderService.hide();
    this.isCampaignTemplateSetLoading = false;
  }

  launchCampaignRoute(campaignId) {
    this.router.navigateByUrl(
      routes.launchCampaign({
        projectId: this.projectId,
        campaignId: campaignId
      })
    );
  }
}
