import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  getInfluencerError, getInfluencersTypes,
  getTemplate,
  getTemplateError, getTemplatePreviewUrl,
  getTemplateSectionError,
  getTemplateSections,
  getTemplateSectionTypes, getTemplateTypes,
  getVariableError,
  getVariables,
  IInfluencersState, InfluencersTypeList,
  ITemplateSectionState,
  ITemplateState,
  IVariableState, ResetInfluencerState, ResetTemplateState, TemplateCreate,
  TemplateGet, TemplateGetTypes, TemplatePreviewURL,
  TemplateSectionListAll,
  TemplateSectionTypeList,
  TemplateUpdate,
  VariableList
} from '@app/stores';
import {LANDING_PAGE_TEMPLATE_TYPE_ID} from '@app/consts';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateEditorComponent} from '@app/_components/template-editor/template-editor.component';
import {InfluencerType, TemplateType} from '@app/modules/shared/models';
import {SocketService} from '@app/services/socket.service';
import * as _ from 'lodash';
import {TemplateRevisionsComponent} from '@app/_components/template-revisions/template-revisions.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit, OnDestroy {

  @ViewChild('templateEditor', {static: false}) templateEditor: TemplateEditorComponent;
  public viewportSize = 'desktop';
  public template: any;
  public isTemplateLoaded = false;
  public rawTemplatesTypes = [];
  public templates = [];
  public stylesForm: UntypedFormGroup;
  public templateForm: UntypedFormGroup;
  public editorInfo = {
    name: 'email-builder-js',
    template_type_id: 2
  };
  public showEditor = {
    'email-builder-js': false
  };
  public templateData  = {
    body: null,
    body_json: null,
  };
  unsubscriber = new Subject();
  public variables = {};
  public templateId: number;
  public templateTypeId: number;
  private saveClick = false;
  private previewClick = false;
  submitted = false;
  influencerTypes: InfluencerType[] = [];
  newDummyInfluencerType: any = {};
  templateTypes: TemplateType[] = [];
  subscription: any;
  queryParams: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private templateSectionStore: Store<ITemplateSectionState>,
    private templateStore: Store<ITemplateState>,
    private variableStore: Store<IVariableState>,
    private influencersStore: Store<IInfluencersState>,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private socketService: SocketService,
    private _modalService: NgbModal
  ) {
    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      if (queryParams.templateType) {
        this.queryParams.templateType = queryParams.templateType;
      }
      if (queryParams.visibility) {
        this.queryParams.visibility = queryParams.visibility;
      }
      if (queryParams.influencerType) {
        this.queryParams.influencerType = queryParams.influencerType;
      }
      if (queryParams.pageNumber) {
        this.queryParams.pageNumber = queryParams.pageNumber;
      }
    });
    this.templateStore.dispatch(ResetTemplateState({params: {previewUrl: null, error: '', success: ''}}));
    this.subscribeStore();
    this.initializeForms();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            const temp = _.cloneDeep(this.templates);
            temp.map(element => {
              if (element.id === message.data.requesterId && message.data?.requesterType === 'section') {
                element.thumbnail = message.data.result.thumbnail;
              }
              return element;
            });
            this.templates = [...temp];
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  initializeForms() {
    this.templateForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      influencer_type_id: new UntypedFormControl(null),
      template_type_id: new UntypedFormControl(null, [Validators.required]),
      visibility: new UntypedFormControl(true)
    });
    this.stylesForm = this.formBuilder.group({
      max_width: new UntypedFormControl(''),
      margin_top: new UntypedFormControl(''),
      background_color: new UntypedFormControl(''),
      font_color: new UntypedFormControl(''),
      header_font_color: new UntypedFormControl(''),
      link_color: new UntypedFormControl(''),
      button_color: new UntypedFormControl(''),
      button_font_color: new UntypedFormControl(''),
      button_border_color: new UntypedFormControl(''),
      button_hover_color: new UntypedFormControl(''),
      font_family: new UntypedFormControl(''),
      header_font_family: new UntypedFormControl(''),
      custom_css: new UntypedFormControl(''),
    });
  }

  get f() {
    return this.templateForm.controls;
  }

  subscribeStore() {
    this.influencersStore.dispatch(ResetInfluencerState({params: {error: '', influencerTypes: []}}));
    this.templateSectionStoreSubscribe();
    this.templateStoreSubscribe();
    this.variableStoreSubscribe();
    this.influencerStoreSubscribe();
  }

  templateSectionStoreSubscribe() {
    this.templateSectionStore.dispatch(TemplateSectionTypeList({params: {}}));
    this.templateSectionStore.pipe(select(getTemplateSections))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSections => {
        if (templateSections && templateSections.length > 0) {
          this.templates = templateSections;
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSectionTypes => {
        if (templateSectionTypes && templateSectionTypes.length > 0) {
          this.rawTemplatesTypes = templateSectionTypes;
          this.fetchRawTemplatesById(this.rawTemplatesTypes[0].id);
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.templateStore.pipe(select(getTemplatePreviewUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(previewUrl => {
        if (previewUrl && previewUrl.url) {
          window.open(previewUrl.url, '_blank');
        }
      });
  }

  templateStoreSubscribe() {
    this.templateStore.pipe(select(getTemplateError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.templateStore.pipe(select(getTemplate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(template => {
        if (template) {
          this.templateId = template.id;
          if (!this.saveClick) {
            this.initializeForms();
            this.replaceTemplate(template);
          }
          if (this.previewClick) {
            this.saveClick = false;
            this.previewClick = false;
            this.templateStore.dispatch(TemplatePreviewURL({templateId: this.templateId}));
          }
          if (this.saveClick) {
            this.onBackButtonClick();
          }
          this.saveClick = false;
          this.submitted = false;
        }
      });
    this.influencersStore.pipe(select(getTemplateTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateTypes => {
        if (templateTypes && templateTypes.length > 0) {
          this.templateTypes = templateTypes;
        }
      });
  }

  influencerStoreSubscribe() {
    this.influencersStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.newDummyInfluencerType.id = null;
          this.newDummyInfluencerType.name = 'All';
          this.influencerTypes = [
            this.newDummyInfluencerType,
            ...influencerTypes
          ];
        }
      });
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          this.variables = variables;
        }
      });
  }

  replaceTemplate(template) {
    if (template) {
      template = {...template};
      if (!template.style) {
        template.style = '<style></style>';
      }
      this.template = template;
      this.templateData = {
        body: template.body,
        body_json: template.body_json
      }      

      if (this.template?.visual_editor) {
        this.editorInfo = this.template.visual_editor;
      } else {
        this.editorInfo = {
          name: "old-editor",
          template_type_id: this.template.template_type_id
        };
      }

      const t = template || {};

      this.templateForm.setValue({
        name: t.name || '',
        influencer_type_id: t.influencer_type_id || null,
        template_type_id: t.template_type_id || '',
        visibility: t.visibility,
      });
      const formFields = {};
      let properties = t.properties;
      if (properties) {
        while (typeof properties === 'string') {
          properties = JSON.parse(properties);
        }
        Object.entries(this.stylesForm.controls).forEach(([key, val]) => {
          formFields[key] = properties.hasOwnProperty(key) ? properties[key] : '';
        });
        this.stylesForm.patchValue(formFields);
      }
    }
  }

  showTemplateRevisions() {
    const modelRef: any = this._modalService.open(TemplateRevisionsComponent, {centered: true, keyboard: true, size: 'xl'});
    modelRef.componentInstance.templateId = this.templateId;
    modelRef.componentInstance.canReset = false;
    modelRef.result.then((result) => {
      if (result.data) {
        this.templateEditor.disableEditor();
        this.replaceTemplate(result.data);
      }
    }, (reason) => {
    });
  }

  showTemplateEditor() {
    const editorName = this.editorInfo?.name !== 'old-editor' ? this.editorInfo.name : 'email-builder-js';
    this.showEditor[editorName] = true;
  }

  onEditorStateChanged(event, editorName) {
    if (editorName == 'email-builder-js') {
      if (event.action === 'save') {
        this.templateData = {
          body: event.body,
          body_json: JSON.parse(event.body_json)
        }
        this.editorInfo.name = 'email-builder-js';
      }

      this.showEditor['email-builder-js'] = false;
    }
  }

  ngOnInit(): void {
    this.variableStore.dispatch(VariableList({params: {}}));
    this.influencersStore.dispatch(InfluencersTypeList());
    this.templateStore.dispatch(TemplateGetTypes());

    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.templateId = Number(params.id);
        this.loadTemplate(params.id);
      }
    });
  }

  loadTemplate(templateId) {
    this.templateStore.dispatch(TemplateGet({templateId}));
  }

  templateLoaded(event) {
    this.isTemplateLoaded = event;
  }

  onStylesCustomizationChange(params) {
    this.stylesForm.patchValue({...params});
  }

  onPageSizeSelect(event) {
    this.viewportSize = event;
  }

  onEditHtml() {
    this.templateEditor.onEditHtml();
  }

  onBackButtonClick() {
    this.router.navigate(['/templates'], {queryParams : this.queryParams});
  }

  async buildTemplateForSave() {
    const htmlParts = await this.templateEditor.buildTemplateForSave();
    const {name, influencer_type_id, template_type_id, visibility} = this.templateForm.value;
    const {value} = this.stylesForm;
    let template: any = {};
    if (this.template) {
      template = {...this.template};
    }

    let htmlBody: any = htmlParts.body;
    htmlBody = htmlBody.replaceAll(' fp-active', '');
    htmlBody = htmlBody.replaceAll('fp-active', '');
    template.body = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
    template.style = htmlParts.style;
    template.name = name;
    template.visibility = visibility;
    template.influencer_type_id = influencer_type_id;
    template.template_type_id = template_type_id;
    // template.template_type_id = LANDING_PAGE_TEMPLATE_TYPE_ID;
    template.properties = {};
    Object.entries(value).forEach(([key, val]) => {
      template.properties[key] = val;
    });
    delete template.content_hash;
    return template;
  }

  async buildEmailTemplateForSave() {
    const {name, influencer_type_id, template_type_id, visibility} = this.templateForm.value;
    const {value} = this.stylesForm;
    let template: any = {};
    if (this.template) {
      template = {...this.template};
    }

    template.body = this.templateData.body;
    template.body_json = JSON.stringify(this.templateData.body_json);
    template.style = null;
    template.name = name;
    template.visibility = visibility;
    template.influencer_type_id = influencer_type_id;
    template.template_type_id = template_type_id;
    template.properties = {};
    template.visual_editor_name = this.editorInfo.name;
    Object.entries(value).forEach(([key, val]) => {
      template.properties[key] = val;
    });
    delete template.content_hash;
    return template;
  }  

  async saveClientTemplate() {
    if(this.templateForm.get('influencer_type_id').value == null || this.templateForm.get('influencer_type_id').value == "null") {
      this.templateForm.patchValue({'influencer_type_id': null});
    }
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    const {template_type_id} = this.templateForm.value;
    const isEmailTemplate = template_type_id == 2;

    this.saveClick = true;
    if (this.templateId) {
      this.templateStore.dispatch(TemplateUpdate({
        id: this.templateId,
        body: isEmailTemplate ? await this.buildEmailTemplateForSave() : await this.buildTemplateForSave()
      }));
    } else {
      this.templateStore.dispatch(TemplateCreate({
        body: isEmailTemplate ? await this.buildEmailTemplateForSave() : await this.buildTemplateForSave()
      }));
    }
  }

  fetchRawTemplatesById(id) {
    this.templateSectionStore.dispatch(TemplateSectionListAll({
      params: {
        template_section_type_id: id,
        template_type_id: LANDING_PAGE_TEMPLATE_TYPE_ID
      }
    }));
  }

  previewTemplate() {
    this.previewClick = true;
    this.saveClientTemplate();
  }

  onChangeVisibility(event) {
    const isChecked = event.target.checked;
    this.templateForm.patchValue({
      visibility: !!isChecked
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.subscription.unsubscribe();
  }
}
