import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {User, Influencer, InfluencerType, Project} from '@app/modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';
import {Constants} from '@app/consts';
import {select, Store} from '@ngrx/store';
import {
  getInfluencer,
  SignedUrl,
  getInfluencerError,
  getInfluencersProjectSegment,
  getInfluencersTypes,
  getInfluencerSuccess,
  getLoggedInUser,
  getProject,
  getProjectError,
  getVariableError,
  getVariables,
  IAuthenticationState,
  IFileUploadState,
  IInfluencersState,
  InfluencersGet,
  InfluencersProjectSegment,
  InfluencersTypeList,
  InfluencersUpdate,
  IProjectState,
  IVariableState,
  ProjectGet,
  ResetFileState,
  VariableList,
  getSignedUrl,
  getUploadToSignedUrl,
  UploadToSignedUrl,
  getFileUploadProgress,
  getSignedUrlError,
  getUploadToSignedUrlError,
  UsersUpdate
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {CreatableSelectComponent} from '@app/_components/creatable-select';
import {FormService} from '@app/services/form.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-influencer-view',
  templateUrl: './influencer-view.component.html',
  styleUrls: ['./influencer-view.component.css'],
  providers: [Constants]
})
export class InfluencerViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() hideClass = false;
  currentUser: User;
  influencer: Influencer = null;
  addSocialClick = true;
  currentUserSubscription: Subscription;
  viewInfluencerForm: UntypedFormGroup;
  submitted = false;
  segmentsGlobal: any = [];
  segments: any = [];
  urlRegex = '';
  loaded = false;
  imageSrc: any;
  showForm = false;
  selectedIndex = false;
  imageProfile = '../../assets/images/blue-logo.png';
  influencerTypes: InfluencerType[] = [];
  unsubscriber = new Subject();
  @Input() projectId: number;
  @Input() influencerId: number;
  @Input() isModal = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('segmentSelect') segmentSelect: CreatableSelectComponent;
  public controls: any = {};
  public variables = {};
  project: Project;
  headshotFile = null;
  headshotSignedUrl = null;
  fileUploadProgress = 0;
  deleteAction = false;
  skipValues: any[] = [];
  selectedSkip = null;

  campaignId;
  influencerType;
  selectedSegment;
  get f() {
    return this.viewInfluencerForm.controls;
  }

  get teamsGroup() {
    return this.viewInfluencerForm.get('teams') as UntypedFormArray;
  }

  get socialsGroup() {
    return this.viewInfluencerForm.get('socials') as UntypedFormArray;
  }

  get sessionArray() {
    console.log("===this.viewInfluencerForm.get('sessions').value", this.viewInfluencerForm.get('sessions').value);
    return this.viewInfluencerForm.get('sessions').value;
  }

  get sessionsGroup() {
    return this.viewInfluencerForm.get('sessions') as UntypedFormArray;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationStore: Store<IAuthenticationState>,
    private influencersStore: Store<IInfluencersState>,
    private fileUploadStore: Store<IFileUploadState>,
    private variableStore: Store<IVariableState>,
    private projectStore: Store<IProjectState>,
    private toastr: ToastrService,
    private constants: Constants,
    public formService: FormService,
    private elRef: ElementRef
  ) {
    this.skipValues = constants.skipValues;
    this.viewInfluencerForm = this.formBuilder.group({});
    this.route.params.subscribe(params => {
      if (params.projectId && params.influencerId) {
        this.projectId = +params.projectId;
        this.influencerId = +params.influencerId;
        this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
        this.influencersStore.dispatch(InfluencersProjectSegment({projectId: this.projectId}));
        this.influencersStore.dispatch(InfluencersGet({influencerId: this.influencerId}));
      }
    });
    this.influencersStore.dispatch(InfluencersTypeList({params: {influencer_fields: true}}));
    this.fileUploadStore.dispatch(ResetFileState({ params: { error: '', file: null } }));
    if(this.route.snapshot.queryParams?.selectedInfluencerType) this.influencerType = this.route.snapshot.queryParams?.selectedInfluencerType;
    if(this.route.snapshot.queryParams?.campaignId) this.campaignId = this.route.snapshot.queryParams?.campaignId;
    if(this.route.snapshot.queryParams?.selectedSegment){
      this.selectedSegment = Number(this.route.snapshot.queryParams?.selectedSegment);
    }
    this.subscribeStore();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectId && changes.projectId.currentValue) {
      this.projectStore.dispatch(ProjectGet({projectId: this.projectId}));
      this.influencersStore.dispatch(InfluencersProjectSegment({projectId: this.projectId}));
    }
    if (changes.influencerId && changes.influencerId.currentValue) {
      this.influencersStore.dispatch(InfluencersGet({influencerId: this.influencerId}));
    }
  }

  subscribeStore() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          this.currentUser = user;
        }
      );

    this.influencersStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.segmentsGlobal = segments;
          this.segments = this.segmentsGlobal;
        }
      });
    this.influencersStore.pipe(select(getInfluencerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
          if (success) {
            if (this.submitted) {
              if (this.campaignId) {
                this.router.navigate([`/project/${this.projectId}/add-influencer`],
                  { queryParams: { campId: this.campaignId , selectedSegmentUrl: this.selectedSegment , selectedInfluencerUrl : this.influencerType } });
              } else {
                this.router.navigate([`/projects/${this.projectId}/influencers`]);
              }
            }
          }
        }
      );
    this.influencersStore.pipe(select(getInfluencer))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencer => {
          if (influencer) {
            this.influencer = influencer;
            this.patchValues();
          }
        }
      );
    this.influencersStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
          if (error) {
            this.toastr.error(error, 'Error');
          }
        }
      );
    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
          this.addSocialClick = true;
          this.patchValues();
        }
      });
    this.fileStoreSubscribe();
    this.variableStoreSubscribe();

    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.project = project;
        }
      });
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.headshotSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.headshotSignedUrl) {
          const image = this.headshotSignedUrl.split('?')[0];
          this.viewInfluencerForm.patchValue({image});
          this.headshotSignedUrl = null;
          this.headshotFile = null;
          this.updateImfluencerData();
        }
      });
  }

  variableStoreSubscribe() {
    this.variableStore.pipe(select(getVariableError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.variableStore.pipe(select(getVariables))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(variables => {
        if (variables) {
          const result = [];
          Object.entries(variables).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        }
      });
  }

  patchValues() {
    if (this.influencer && this.influencerTypes && this.influencerTypes.length > 0) {
      this.createForm(this.influencer.influencer_type_id);
      this.filterSegments(false);
      const values: any = {};
      Object.keys(this.controls).forEach(key => {
        if (key === 'sessions') {
          values[key] = [];
          if (this.influencer[key]) {
            values[key] = this.influencer[key].replace(/"/g, '').split('|').map(item => ({'session': item}));
          }
        } else {
          values[key] = this.influencer[key];
        }
      });
      this.imageSrc = values['image'] || '';
      this.viewInfluencerForm.patchValue(values);
      this.formService.setNewOrExistingSegmentValidation(this.viewInfluencerForm, 1);
    }
  }

  filterSegments(resetFields = true) {
    if (resetFields) {
      this.viewInfluencerForm.patchValue({project_influencer_segment_new_name: '', project_influencer_segment_id: ''});
    }
    const influencerType = this.viewInfluencerForm.get('influencer_type_id').value;
    if (influencerType) {
      if (this.segmentSelect) {
        this.segmentSelect.clearSelection();
      }
      this.segments = this.segmentsGlobal.filter(
        p => p.influencer_type_id === Number(this.viewInfluencerForm.get('influencer_type_id').value)
      );
    }
  }

    createForm(influencerType: number) {

    const validators = {
      first_name: [Validators.required],
      name: [Validators.required],
      influencer_type_id: [Validators.required],
      project_influencer_segment_id: [Validators.required],
      last_name: [Validators.required],
      email: [Validators.required, Validators.email],
      registration_url: [Validators.pattern(this.constants.urlRegex)],
      video_url: [],
      skip:[],
      title: []
    };
    this.controls = {};

    Object.keys(this.viewInfluencerForm.controls).forEach(key => {
      this.viewInfluencerForm.removeControl(key);
    });
    influencerType = Number(influencerType);
    this.showForm = false;
    this.viewInfluencerForm.addControl('project_influencer_segment_new_name', new UntypedFormControl(''));
    this.viewInfluencerForm.addControl('skip', new UntypedFormControl());
    this.viewInfluencerForm.addControl('email_source_message', new UntypedFormControl());
    // this.addInfluencerForm.addControl('facebook', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
    // this.addInfluencerForm.addControl('twitter', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
    // this.addInfluencerForm.addControl('linkedin', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
    // this.addInfluencerForm.addControl('instagram', new FormControl('', [Validators.pattern(this.constants.urlRegex)]));
    // this.addInfluencerForm.addControl('socials', new FormArray([]));
    // this.addInfluencerForm.addControl('teams', new FormArray([this.addTeamsFormGroup()]));

    const influencer = this.influencerTypes.find(item => item.id === influencerType);
    const fieldsObject = influencer.fields;
    Object.keys(fieldsObject).forEach(key => {
      if (fieldsObject[key].type === 'all') {
        if (fieldsObject[key].editable) {
          this.addControls(fieldsObject, key, validators);
        }
      } else {
        if (fieldsObject[key].type.includes(influencer.slug)) {
          if (fieldsObject[key].editable) {
            this.addControls(fieldsObject, key, validators);
          }
        }
      }
    });
    this.viewInfluencerForm.patchValue({influencer_type_id: influencerType});
    this.selectedSkip = this.skipValues.find(x => x.value ===  this.influencer['skip'] || null);
    this.viewInfluencerForm.patchValue({skip: this.selectedSkip?.value || null});
    this.showForm = true;
  }

  addControls(fieldsObject, key, validators) {
    this.controls[key] = key;
    if (key === 'sessions') {
      let total = [this.addSessionFormGroup()];
      if (this.influencer[key]) {
        total = this.influencer[key].split('|');
        if (total.length > 0) {
          total = total.map(item => this.addSessionFormGroup());
        } else {
          total = [this.addSessionFormGroup()];
        }
      }
      this.viewInfluencerForm.addControl('sessions', new UntypedFormArray(total));
    } else {
      this.viewInfluencerForm.addControl(key, new UntypedFormControl('', validators[key] || []));
    }
  }

  segmentSelected(event: any) {
    if (event) {
      if (event.isNew) {
        this.viewInfluencerForm.patchValue({project_influencer_segment_new_name: event.value});
        this.formService.setNewOrExistingSegmentValidation(this.viewInfluencerForm, 0);
      } else {
        this.viewInfluencerForm.patchValue({project_influencer_segment_id: event.value});
        this.formService.setNewOrExistingSegmentValidation(this.viewInfluencerForm, 1);
      }
    } else {
      this.viewInfluencerForm.patchValue({project_influencer_segment_new_name: '', project_influencer_segment_id: ''});
      this.formService.setNewOrExistingSegmentValidation(this.viewInfluencerForm, 0);
    }
  }

  skipSelected(event: any) {
    if(event?.value){
      this.selectedSkip = this.skipValues.find(x => x.id === event.value || null);
      this.viewInfluencerForm.patchValue({skip: this.selectedSkip.value});
    }
    else {
      this.viewInfluencerForm.patchValue({skip: null});
    }
  }

  ngOnInit() {
    this.variableStore.dispatch(VariableList({params: {}}));
  }

  addTeamsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      profile_img: [''],
      title: [''],
      first_name: [''],
      last_name: [''],
      email: [''],
      phone: [''],
      job_title: [''],
      skip: ['']
    });
  }

  addSocialsFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      social_label: [''],
      social_value: ['', [Validators.pattern(this.constants.urlRegex)]]
    });
  }

  addSessionFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      session: [''],
    });
  }

  activeFileInput(fileInput) {
    fileInput.click();
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.headshotFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.headshotFile.name, contentType: this.headshotFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.headshotFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageSrc = data;
        this.loaded = true;
      });
    }
  }

  onProfileChanged(event, index) {
    if (event.target.files && event.target.files.length) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.teamsGroup.at(index).patchValue({profile_img: data});
      });
    }
  }

  addTeamsButtonClick(): void {
    this.teamsGroup.push(this.addTeamsFormGroup());
  }

  addSessionButtonClick(): void {
    this.sessionsGroup.push(this.addSessionFormGroup());
  }

  addSocialsButtonClick(): void {
    this.socialsGroup.push(this.addSocialsFormGroup());
  }

  removeTeamsButtonClick(index: number): void {
    this.teamsGroup.removeAt(index);
  }

  removeSocialsButtonClick(index: number): void {
    this.socialsGroup.removeAt(index);
  }

  removeSessionButtonClick(index: number): void {
    this.sessionsGroup.removeAt(index);
  }

  updateInfluencer() {
    this.submitted = true;
    if (this.viewInfluencerForm.invalid) {
      setTimeout(() => {
        this.scrollToField();
      }, 500);
      return;
    }

    if (this.headshotSignedUrl && this.headshotFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.headshotSignedUrl, fileData: this.headshotFile}]}));
    } else {
      this.updateImfluencerData();
    }
  }

  updateImfluencerData() {
    const body = this.viewInfluencerForm.value;
    const sessions = [];
    if (body.hasOwnProperty('sessions')) {
      body.sessions.forEach(item => {
        sessions.push(item.session);
      });
    }

    body.sessions = sessions.filter((item) => item).join('|');

    this.influencersStore.dispatch(InfluencersUpdate({
      influencerId: this.influencerId,
      influencer: {
        project_id: this.projectId,
        skipped_by: this.viewInfluencerForm.value.skip ? this.currentUser.id : null,
        ...body
      }
    }));
  }

  updateValue(variable, textFor) {
    const value = this.viewInfluencerForm.get(textFor).value;
    const varValue = variable.key;
    this.viewInfluencerForm.controls[textFor].setValue(`${value} ${varValue}`);
  }

  getImageType() {
    const influencersType = this.viewInfluencerForm.get('influencer_type_id').value;
    const type = this.influencerTypes.find(item => item.id === influencersType);
    if (type) {
      if (type.slug === 'attendee' || type.slug === 'speaker') {
        return 'Headshot';
      } else if (type.slug === 'partner') {
        return 'Logo';
      }
    }
    return 'Logo or Headshot';
  }

  deleteImage() {
    Swal({
      title: `Are you sure you ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.deleteAction = true;
        this.viewInfluencerForm.patchValue({image: ''});
        this.imageSrc = null;
        this.headshotFile = null;
      }
    });
  }


  scrollToField() {
    const invalid = this.elRef.nativeElement.querySelector('.invalid-text');
    if (invalid) {
      this.toastr.warning('Some fields have error, please review', 'warning');
      const doc = this.isModal ? document.querySelector('.custom-modal .modal-dialog .modal-body') : window;
      doc.scrollBy({
        top: invalid.getBoundingClientRect().top - 150,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

}
